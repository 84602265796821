import { CircularProgress, SwipeableDrawer } from "@mui/material";
import { useEffect, useState } from 'react';
import { useCart } from '../../hook//useCart';

import './index.css'
import EmptyCartComponent from "../EmptyCart/EmptyCartComponent";
import StepperCartComponent from "../StepperCart/StepperCartComponent";

export default function CartComponent() {

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const { isLoadingCartProducts, cartProducts, isVisibleCart, isSendingOrder, isGettingPublicKey } = useCart();

    useEffect(() => {
        if (isVisibleCart) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'initial';
        }
    }, [isVisibleCart]);

    // function calcTotalCartValue() {
    //     let totalValue = 0;

    //     cartProducts.forEach(product => {
    //         totalValue += product.price;
    //     })

    //     return totalValue;
    // }

    return (
        <>
            <li>
                <button onClick={() => setOpenDrawer(true)}>
                    <i className="fas fa-cart-shopping"></i>
                    <p>Carrinho</p>
                </button>
                {
                    cartProducts.length > 0
                    &&
                    <div className="badge-count">{cartProducts.length}</div>
                }
            </li>

            <SwipeableDrawer
                anchor='right'
                open={openDrawer}
                onClose={(isSendingOrder || isGettingPublicKey) ? () => { } : () => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                PaperProps={{ sx: { width: '700px', maxWidth: '100%' } }}
            >
                <div className="cart-drawer">
                    <div className="cart-drawer-header">
                        <button disabled={isSendingOrder || isGettingPublicKey} onClick={() => setOpenDrawer(false)}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div className="cart-drawer-content">
                        {
                            isLoadingCartProducts
                                ?
                                <div className="cart-modal-loading">
                                    <CircularProgress />
                                </div>
                                :

                                cartProducts.length === 0
                                    ?

                                    <div className="cart-drawer-empty-cart-group">
                                        <EmptyCartComponent />
                                    </div>
                                    :
                                    <StepperCartComponent />


                        }
                    </div>
                </div>
            </SwipeableDrawer>
        </>
    )
}