import { Button, Popover } from "@mui/material";
import './index.css';
import { useState } from "react";
import { IAdditional } from "../ModalProduct/ModalProduct";

interface IPopoverItensKitProps {
    additionals: IAdditional[];
}

export default function PopoverAdicionais({ additionals }: IPopoverItensKitProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Button variant="outlined" onClick={handleClick}>
                Adicionais
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="popover-itens-kit">
                    <p className="popover-itens-kit-title">Adicionais</p>
                    {
                        additionals.map((item, index) => (
                            <p key={index} className="popover-itens-kit-item"><b>{item.quantidade}x</b> - {item.product.Descricao}.</p>
                        ))
                    }

                </div>
            </Popover>
        </>
    )
}