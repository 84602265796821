import { format } from "date-fns";

export function formatUmaCasaDecimal(value: number) {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 1, maximumFractionDigits: 1 })
}

export function formatMoney(value: number) {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
export function formatTresCasasDecimais(value: number) {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
}

export function removeMask(value: string) {
    return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
}
export function removeMoneyMask(value: string) {
    return value.replaceAll('.', '').replaceAll(',', '.');
}

export function addPhoneMask(phone?: string) {
    if (!phone) {
        return '';
    }

    var value = removeMask(phone).replaceAll(' ', '');

    if (value.length == 10) {
        var ddd = value.slice(0, 2);
        var initial = value.slice(2, 6);
        var final = value.slice(6, 10);

        return `(${ddd}) ${initial}-${final}`;
    }
    if (value.length == 11) {
        var ddd = value.slice(0, 2);
        var initial = value.slice(2, 7);
        var final = value.slice(7, 11);

        return `(${ddd}) ${initial}-${final}`;
    }

    return value;
}

export function addCpfMask(cpf?: string) {

    if (cpf && cpf.length === 11) {
        return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6, 9)}-${cpf.slice(9, 11)}`;
    }

    return '';
}

export function ocultCpf(cpf?: string) {
    if (cpf && cpf.length === 14) {
        const split2 = cpf.split("-");
        const split = split2[0].split(".");

        return `•••.${split[1]}.${split[2]}-••`;
    }
}

export function formatHour(value: string) {
    const result = value.split(":");

    if (result[0] && result[1]) {
        return `${result[0]}:${result[1]}`;
    }

    return '';
}

export function validaCpf(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    var i: number;

    if (strCPF == "00000000000" ||
        strCPF == "11111111111" ||
        strCPF == "22222222222" ||
        strCPF == "33333333333" ||
        strCPF == "44444444444" ||
        strCPF == "55555555555" ||
        strCPF == "66666666666" ||
        strCPF == "77777777777" ||
        strCPF == "88888888888" ||
        strCPF == "99999999999") return false;

    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

export function validaCNPJ(cnpj: string) {

    var cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += +numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != +digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += +numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != +digitos.charAt(1))
        return false;

    return true;

}

export function getDateTimeFromString(date: string, time: string) {
    return new Date(`${date} ${time}`);
}

export function convertKgInGr(value: number) {
    return value * 1000;
}

export function formatPeso(pesoEmGr: number): string {
    if (pesoEmGr > 1000) {
        var val = pesoEmGr / 1000;
        return `${val}kg`;
    }
    else {
        return `${pesoEmGr}g`;
    }
}

export function returnNumberOrZero(value?: number) {
    if (value) {
        return value;
    }

    return 0;
}

export function formatDateToBr(date: Date) {
    return format(date, 'dd/MM/yyyy');
}

export function formatDate(date: Date | null) {
    if (!date) {
        return format(new Date(), 'yyyy-MM-dd');
    }
    return format(date, 'yyyy-MM-dd');
}

export function formatHourFromDate(date: Date) {
    return format(date, 'HH:mm');
}
export function getTimeFromDate(date: Date | null) {
    if (!date) {
        return null;
    }
    return format(date, 'hh:mm:ss');
}

