import './index.css';
import Logo from '../../assets/thr_dark.png';

export default function FooterComponent() {
    return (
        <div className='footer'>
            <span>Desenvolvido por</span>
            <img src={Logo} alt="" />
        </div>
    )
}