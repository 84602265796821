import './index.css'
import Chip from '@mui/material/Chip';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import OrderStatusBarComponent from '../OrderStatusBar/OrderStatusBarComponent';
import { IAgendamento } from '../../interfaces/IAgendamento';

interface AgendamentoCardProps {
    agendamento: IAgendamento;
}

export default function AgendamentoCardComponent({ agendamento }: AgendamentoCardProps) {

    function convertMoney(value: number) {
        return Number(value).toFixed(2).replace(".", ",");
    }

    function convertQtd(value: number) {
        return Number(value).toFixed(0);
    }

    return (
        <div className='order-card'>
            <div className='order-card-title'>#{agendamento.Id}</div>
            <div className='order-card-status'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-spinner"></i>
                    </div>
                    Status do pedido
                </div>
                <OrderStatusBarComponent status={agendamento.Status} />
            </div>
            <div className='order-card-info'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-receipt"></i>
                    </div>
                    Pedido agendado para
                </div>
                <div className='order-card-info-content'>
                    {agendamento.DtEntrega && <Chip icon={<CalendarMonthIcon />} label={format(new Date(agendamento.DtEntrega).setUTCHours(24), 'dd/MM/yyyy').toString()} variant="outlined" />}
                    {agendamento.HrEntrega && <Chip icon={<AccessTimeIcon />} label={(agendamento.HrEntrega).toString().slice(0, 5)} variant="outlined" />}
                </div>
            </div>
            <div className='order-card-info'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-receipt"></i>
                    </div>
                    Informações do pedido
                </div>
                <div className='order-card-info-content'>
                    <Chip icon={<CalendarMonthIcon />} label={format(new Date(agendamento.Data).setUTCHours(24), 'dd/MM/yyyy').toString()} variant="outlined" />
                    <Chip icon={<AccessTimeIcon />} label={(agendamento.Hora).toString().slice(0, 5)} variant="outlined" />
                    <Chip icon={<PaymentIcon />} label={agendamento.tipo_pagto === "O" ? "Online" : agendamento.TipoPagEntrega == "D" ? "Dinheiro" : "Cartão"} variant="outlined" />
                </div>
            </div>
            <div className='order-card-items'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-shopping-bag"></i>
                    </div>
                    Itens do pedido
                </div>
                <div className='order-card-items-container'>
                    {agendamento.agendamento_i.map(agendamentoItem => {
                        return (
                            <div className='order-card-items-container-item' key={agendamentoItem.Id}>
                                <div className='order-card-items-container-item-info'>
                                    <div>
                                        {
                                            agendamentoItem?.FatorPeso && agendamentoItem?.FatorPeso > 0 ?
                                                <>
                                                    <b>{(agendamentoItem?.Qtd * agendamentoItem?.FatorPeso).toFixed(3)}kg</b> {(agendamentoItem.produto?.Descricao)?.toLowerCase()}
                                                </>
                                                :
                                                <>
                                                    <b>{convertQtd(agendamentoItem?.Qtd)}x</b> {(agendamentoItem.produto?.Descricao)?.toLowerCase()}
                                                </>
                                        }
                                    </div>
                                    {
                                        agendamentoItem.OBS &&
                                        <div className='order-card-items-container-item-info-observation'>
                                            <div>Observação:</div>
                                            <div>{agendamentoItem.OBS}</div>
                                        </div>
                                    }
                                    <div className='order-card-items-container-item-price'>
                                        R$ {convertMoney(Number(agendamentoItem.Unit) * agendamentoItem.Qtd)}
                                    </div>
                                </div>
                                <div className='order-card-items-container-item-info-image'>
                                    <div className='order-card-items-container-item-image'>
                                        {
                                            agendamentoItem?.produto?.Imagem !== null && agendamentoItem?.produto?.Imagem !== '' && agendamentoItem?.produto?.Imagem !== undefined
                                            &&
                                            <img src={`${process.env.REACT_APP_IMAGE_URL}/${(agendamentoItem?.produto?.Imagem)?.split(',')[0]}`} />

                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='order-card-items-container-item'>
                        <div>Subtotal</div>
                        <div>{`R$ ${convertMoney(Number(agendamento.Valor))}`}</div>
                    </div>
                    <div className='order-card-items-container-item'>
                        <div>Taxa de entrega</div>
                        <div>{`R$ ${convertMoney(Number(agendamento.ValorFrete))}`}</div>
                    </div>
                    <div className='order-card-items-container-item'>
                        <div> <b>Total</b></div>
                        <div> <b>{`R$ ${convertMoney(Number(agendamento.Valor) + Number(agendamento.ValorFrete))}`}</b></div>
                    </div>
                </div>
            </div>
        </div>
    )
}