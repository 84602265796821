/* eslint-disable @typescript-eslint/no-explicit-any */
import customToast from "../toast/customToast";

export function AppError(err: any) {

    if (err.response.data.message) {
        customToast.error(err.response.data.message);
        return;
    }

    customToast.error(`Ocorreu um erro inesperado - ${err.response.data}`);
}