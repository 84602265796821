import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface IConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    title: string;
    text: string;
}

export default function ConfirmationDialog({ open, onClose, onConfirm, title, text }: IConfirmationDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{ sx: { margin: '12px', width: 'calc(100% - 24px)' } }}
        >
            <DialogTitle>
                {title}
            </DialogTitle>

            <DialogContent dividers>
                {text}
            </DialogContent>

            <DialogActions>
                <LoadingButton onClick={onClose} className="default-button">Fechar</LoadingButton>
                {
                    onConfirm &&
                    <LoadingButton onClick={onConfirm}>
                        Confirmar
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog >
    )
}