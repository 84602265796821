import { ReactNode, useEffect } from "react";
import { useState, createContext } from 'react';
import { api } from "../lib/api";
import ICompany from "../interfaces/ICompany";

export interface CompanyContextDataProps {
    company: ICompany | null;
    isLoadingCompany: boolean;
    aberto: boolean;
    loadCompany: (id: number) => Promise<void>;
}

interface CompanyProviderProps {
    children: ReactNode;
}

export const CompanyContext = createContext({} as CompanyContextDataProps);

export function CompanyContextProvider({ children }: CompanyProviderProps) {
    const [company, setCompany] = useState<ICompany | null>(null);
    const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(true);

    const [aberto, setAberto] = useState<boolean>(false);

    useEffect(() => {
        loadCompany();
    }, [])

    useEffect(() => {
        setAberto(verifyIfIsOpen());
    }, [company])

    async function loadCompany() {
        setIsLoadingCompany(true);

        try {
            const res = await api.get(`/company/${process.env.REACT_APP_ID_EMPRESA}`);

            setCompany(res.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingCompany(false);
        }
    }

    function verifyIfIsOpen() {
        if (company?.Aberto === 'S') {
            return true;
        }

        return false;
    }


    return (
        <CompanyContext.Provider
            value={{
                company,
                isLoadingCompany,
                aberto,
                loadCompany,
            }}
        >
            {children}
        </CompanyContext.Provider>
    )

}