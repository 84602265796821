import { useEffect, useState } from "react";
import IPixPaymentResponse from "../../../interfaces/Pagseguro/IPixPaymentResponse"
import './index.css'
import { addMinutes, format, isAfter, subMinutes } from "date-fns";
import { api } from "../../../lib/api";
import { IOrder } from "../../../interfaces/IOrder";
import { IAgendamento } from "../../../interfaces/IAgendamento";
import customToast from "../../../toast/customToast";
import Logo from '../../../assets/doce_receita.png';
import { useCompany } from "../../../hook/useCompany";

interface QRCodePaymentStepProps {
    pixPaymentResponse: IPixPaymentResponse;
    cartType: ("Pronta Entrega" | "Encomenda");
    onConfirmPayment: () => void;
    onClear: () => void;
}

export default function QRCodePaymentStep({ pixPaymentResponse, cartType, onConfirmPayment, onClear }: QRCodePaymentStepProps) {

    const [isClicked, setIsClicked] = useState(false);

    const { company } = useCompany();

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;


        Notification.requestPermission();

        if (cartType == "Pronta Entrega") {
            interval = setInterval(() => {
                verifyPedido();
            }, 10000);
        } else {
            interval = setInterval(() => {
                verifyAgendamento();
            }, 10000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    function copyToClipboard() {
        handleClick();
        // Get the text field
        var copyText = document.getElementById("qrcode-code") as HTMLInputElement;

        if (copyText) {
            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices

            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);
        }

    }

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false);
        }, 200); // Tempo da animação, em milissegundos
    };

    async function verifyPedido() {
        try {
            const result: { data: IOrder } = await api.get(`/order/${pixPaymentResponse.reference_id}`);

            if (result.data.status_pagto === "PG") {
                showNotification(`Pedido aprovado em ${company?.Fantasia}`, 'Recebemos o pagamento e seu pedido foi enviado à loja, agora é só aguardar! Obrigado')

                localStorage.removeItem("app_items");
                onConfirmPayment();
            }

            // Remove o QR Code após a data de expiração
            if (pixPaymentResponse.qr_codes.length > 0) {
                if (isAfter(subMinutes(new Date(), 1), new Date(pixPaymentResponse.qr_codes[0].expiration_date))) {
                    customToast.warning("QR Code expirado");
                    onClear();
                }
            }

        } catch (err) {

        }
    }

    async function verifyAgendamento() {
        try {
            const result: { data: IAgendamento } = await api.get(`/agendamento/${pixPaymentResponse.reference_id}`);

            if (result.data.status_pagto === "PG") {
                showNotification(`Pedido aprovado em ${company?.Fantasia}`, 'Recebemos o pagamento e seu pedido foi enviado à loja, agora é só aguardar! Obrigado')

                localStorage.removeItem("app_items");
                onConfirmPayment();
            }

            // Remove o QR Code após a data de expiração
            if (pixPaymentResponse.qr_codes.length > 0) {
                if (isAfter(subMinutes(new Date(), 1), new Date(pixPaymentResponse.qr_codes[0].expiration_date))) {
                    customToast.warning("QR Code expirado");
                    onClear();
                }
            }

        } catch (err) {

        }
    }

    function showNotification(title: string, description: string) {
        new Notification(title, {
            body: description,
            icon: Logo,
        });
    }


    return (
        <div className="qrcode-step">

            <div className="qrcode-step-header">
                <div className="qrcode-step-header-title">Pedido aguardando pagamento</div>
                <div className="qrcode-step-header-subtitle">Escaneie o QR Code ou copie o código abaixo para pagar via pix </div>
                {
                    pixPaymentResponse.qr_codes.length > 0 &&
                    <div className="qrcode-step-header-subtitle">Esse QR Code é valido até <b>{format(new Date(pixPaymentResponse.qr_codes[0].expiration_date), 'HH:mm')}</b> </div>
                }
            </div>
            <div className="qrcode-step-image">
                {
                    pixPaymentResponse.qr_codes.length > 0 && pixPaymentResponse.qr_codes[0].links.length > 0 &&
                    <img src={pixPaymentResponse.qr_codes[0].links[0].href} alt="" />
                }
            </div>

            {
                pixPaymentResponse.qr_codes.length > 0 &&
                <div className="qrcode-step-text-code">
                    <input disabled id="qrcode-code" value={pixPaymentResponse.qr_codes[0].text} className="qrcode-step-text-code-value" />
                    <button className={`${isClicked ? 'clicked' : ''}`} onClick={copyToClipboard}>
                        <i className="fa-solid fa-copy"></i>
                    </button>
                </div>
            }


        </div>
    )
}