import { ICombo } from "../../interfaces/ICombo"
import { IGroup } from "../../interfaces/IGroup"
import { IProduct } from "../../interfaces/IProduct";
import ProductCardComponent from "../ProductCardComponent/ProductCardComponent";
import './index.css';

interface IGrupoProps {
    grupo: IGroup | ICombo;
    selectProduct: (product: IProduct) => void;
}


export default function Grupo({ grupo, selectProduct }: IGrupoProps) {
    return (
        <div>
            <div className="grupo-header">

                <div className="grupo-header-info">
                    <div className="grupo-header-title">
                        <div className="grupo-header-badge">
                            <i className="fa-solid fa-star"></i>
                        </div>
                        <span>
                            {"Descricao" in grupo ? grupo.Descricao : grupo.Nome}
                        </span>
                    </div>
                    <div className="grupo-header-subtitle">{grupo.descritivo}</div>
                </div>
            </div>
            <div className='products-container'>
                {
                    grupo.products.map(product => {
                        return (
                            <ProductCardComponent key={`group-${product.Id}`} product={product} onClick={selectProduct} />
                        )
                    })
                }
            </div>
        </div>
    )
}