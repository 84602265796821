import { Link, useNavigate } from 'react-router-dom'
import CartComponent from '../../Cart/CartComponent'
import './index.css'
import { useCart } from '../../../hook/useCart'
import { useState } from 'react';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';

interface INavbarProps {
    currentScreen?: ("Encomenda" | "Pronta Entrega");
}

export default function NavBarComponent({ currentScreen = "Pronta Entrega" }: INavbarProps) {

    const { setIsVisibleCart } = useCart();
    const { cartProducts } = useCart();
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");

    function handleToEncomenda() {
        const items = cartProducts.filter(element => element.type === 'Pronta Entrega');

        if (items.length > 0) {
            setDescription("Você tem itens no carrinho do tipo pronta-entrega. Por favor, finalize seu pedido atual antes de selecionar a opção de Encomenda");
            setOpenDialog(true);
            return;
        }

        navigate(`/encomenda`);

    }

    function handleToProntaEntrega() {
        const items = cartProducts.filter(element => element.type === 'Encomenda');

        if (items.length > 0) {
            setDescription("Você tem itens no carrinho do tipo encomenda. Por favor, finalize seu pedido atual antes de selecionar a opção de pronta-entrega");
            setOpenDialog(true);
            return;
        }

        navigate(`/`);

    }

    return (
        <>
            <nav className="navbar">
                <div className='navbar-logo'></div>
                <ul>
                    {
                        currentScreen == "Pronta Entrega" ?
                            <li className='navbar-first-li'>
                                <button onClick={() => handleToEncomenda()} className='navbar-item-inline navbar-dark-button'>
                                    <i className="fa-solid fa-truck-ramp-box"></i>
                                    <p>Faça sua encomenda</p>
                                </button>
                            </li>
                            :
                            <li className='navbar-first-li'>
                                <button onClick={() => handleToProntaEntrega()} className='navbar-item-inline navbar-dark-button'>
                                    <i className="fa-solid fa-truck-ramp-box"></i>
                                    <p>Pronta-entrega</p>
                                </button>
                            </li>
                    }
                    <div className='navbar-ul-right'>
                        <li>
                            <Link to="/" onClick={() => setIsVisibleCart(false)}>
                                <i className="fas fa-house"></i>
                                <p>Inicio</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/orders" onClick={() => setIsVisibleCart(false)}>
                                <i className="fas fa-scroll"></i>
                                <p>Pedidos</p>
                            </Link>
                        </li>
                        <CartComponent />
                    </div>
                </ul>
            </nav>
            <ConfirmationDialog open={openDialog} title='Finalize seu pedido' text={description} onClose={() => setOpenDialog(false)} />
        </>
    )
}