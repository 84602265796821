import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { api } from '../lib/api';
import customToast from '../toast/customToast';
import { IProduct } from '../interfaces/IProduct';
import { IAdditional } from '../components/ModalProduct/ModalProduct';

interface ComboItemInCartProps {
    productId: number;
    description: string;
}

export interface CartProductProps {
    id: number;
    description: string;
    quantity: number;
    unit_price: number;
    price: number;
    fatorPeso: number;
    image: string | null;
    observation: string;
    orderItemsCombo: ComboItemInCartProps[];
    selectedAdditionals: IAdditional[];
    type: ("Encomenda" | "Pronta Entrega");
}

export interface CartContextDataProps {
    cartProducts: CartProductProps[];
    cartType: ("Encomenda" | "Pronta Entrega" | null);
    setCartType: Dispatch<SetStateAction<("Encomenda" | "Pronta Entrega" | null)>>
    isLoadingCartProducts: boolean;
    isVisibleCart: boolean;
    userOrder: string;
    isGettingPublicKey: boolean;
    isSendingOrder: boolean;
    setIsGettingPublicKey: Dispatch<SetStateAction<boolean>>;
    setIsSendingOrder: Dispatch<SetStateAction<boolean>>;
    setUserOrder: (user: string) => void;
    setIsVisibleCart: (status: boolean) => void;
    addCartProduct: (product: IProduct, quantity: number, valorTotal: number, fatorPeso: number, observation: string, type: ("Encomenda" | "Pronta Entrega"), comboItems: ComboItemInCartProps[], selectedAdditionals: IAdditional[]) => Promise<void>;
    removeCartProduct: (id: number, index: number) => Promise<void>;
    deleteCart: () => Promise<void>;
}

interface CartProviderProps {
    children: ReactNode;
}

export const CartContext = createContext({} as CartContextDataProps);


export function CartContextProvider({ children }: CartProviderProps) {
    const [cartProducts, setCartProducts] = useState<CartProductProps[]>([]);
    const [isLoadingCartProducts, setIsLoadingCartProducts] = useState<boolean>(false);
    const [cartType, setCartType] = useState<("Encomenda" | "Pronta Entrega" | null)>("Pronta Entrega");

    const [isVisibleCart, setIsVisibleCart] = useState<boolean>(false);

    const [isGettingPublicKey, setIsGettingPublicKey] = useState<boolean>(false);
    const [isSendingOrder, setIsSendingOrder] = useState<boolean>(false);

    const [userOrder, setUserOrder] = useState<string>('');

    useEffect(() => {
        loadItems();
    }, [cartType]);

    function loadItems() {
        try {
            const localStorageItems = localStorage.getItem('app_items');

            if (localStorageItems) {

                const items: CartProductProps[] = JSON.parse(localStorageItems);

                const products = items.filter(item => item.type === cartType);

                localStorage.setItem("app_items", JSON.stringify(products));

                setCartProducts(products);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function removeCartProduct(id: number, indexToDelete: number) {
        setIsLoadingCartProducts(true);

        let items: CartProductProps[] = cartProducts;

        let removedItem = items.filter((item, index) => index !== indexToDelete);

        setCartProducts(removedItem);

        setIsLoadingCartProducts(false);
    }

    async function deleteCart() {
        setIsLoadingCartProducts(true);

        setCartProducts([]);

        setIsLoadingCartProducts(false);
    }

    async function addCartProduct(product: IProduct, quantity: number, valorTotal: number, fatorPeso: number, observation: string, type: ("Encomenda" | "Pronta Entrega"), comboItems: ComboItemInCartProps[], selectedAdditionals: IAdditional[]) {

        let productsInCart: CartProductProps[] = cartProducts;

        if (cartType !== type) {
            if (cartProducts.length == 0) {
                setCartType(type);
                localStorage.setItem("cartType", type);
            } else {
                customToast.warning(`Por favor, verifique se o carrinho é do tipo ${cartType}`);
                return;
            }
        }

        try {

            const items = [...productsInCart, {
                id: product.Id,
                description: product.Descricao,
                quantity: quantity,
                unit_price: product.Valor,
                price: valorTotal,
                fatorPeso: fatorPeso,
                image: product.Imagem,
                orderItemsCombo: comboItems,
                observation: observation,
                type: type,
                selectedAdditionals,
            }];

            customToast.success('Produto adicionado ao carrinho!');

            localStorage.setItem('app_items', JSON.stringify(items));

            setCartProducts(items);

        } catch (err) {
            console.log(err);
        } finally {

        }

    }

    return (
        <CartContext.Provider
            value={{
                cartProducts,
                cartType,
                setCartType,
                isLoadingCartProducts,
                isVisibleCart,
                setIsVisibleCart,
                userOrder,
                setUserOrder,
                addCartProduct,
                isGettingPublicKey,
                isSendingOrder,
                setIsGettingPublicKey,
                setIsSendingOrder,
                removeCartProduct,
                deleteCart
            }}
        >
            {children}
        </CartContext.Provider>
    )
}