import { Dispatch, SetStateAction, useEffect, useState } from "react";
import './index.css';
import { formatMoney } from "../../utils/utils";
import { IAdditional } from "../ModalProduct/ModalProduct";
import IProdutoAdicional from "../../interfaces/IProdutoAdicional";

interface IAdicionalItemProps {
    additional: IProdutoAdicional;
    additionals: IAdditional[];
    setAdditionals: Dispatch<SetStateAction<IAdditional[]>>;
}

export default function AdicionalItem({ additional, additionals, setAdditionals }: IAdicionalItemProps) {
    const [quantidade, setQuantidade] = useState<number>(0);

    useEffect(() => {
        changeAndditionals();
    }, [quantidade]);

    function addQuantity() {
        setQuantidade(prev => prev + 1);
    }

    function removeQuantity() {
        if (quantidade > 0) {
            setQuantidade(prev => prev - 1);
        }
    }

    function changeAndditionals() {
        let items = additionals;

        if (quantidade === 0) {
            const item = items.filter(element => element.id !== additional.id);

            setAdditionals([...item]);
        }
        else {
            const item = items.findIndex(item => additional.id === item.id);

            if (item >= 0) {
                items[item].quantidade = quantidade;
            } else {
                items.push({
                    id: additional.id,
                    quantidade,
                    product: additional.produto_adicional,
                });
            }

            setAdditionals([...items]);
        }

    }

    return (
        <div className="adicional-item">
            <div className="adicional-item-main">
                <div>
                    <div className="adicional-item-name">{additional.produto_adicional.Descricao}</div>
                    <div className="adicional-item-description">{additional.produto_adicional.Descritivo}</div>
                </div>
                <div className="adicional-item-main-price">R$ {formatMoney(+additional.produto_adicional.Valor)}</div>
            </div>
            <div className="adicional-item-counter">
                <button onClick={removeQuantity}>-</button>
                <div>{quantidade}</div>
                <button onClick={addQuantity}>+</button>
            </div>
        </div>
    )
}