import { useState, useEffect } from 'react';
import { IMaskInput } from "react-imask";

import { ptBR } from '@mui/x-date-pickers/locales';
import { ptBR as ptBRfns } from 'date-fns/locale';

// import './Orders.css';
import { api } from '../../lib/api';
import customToast from '../../toast/customToast';
import OrderCardComponent from '../../components/OrderCard/OrderCardComponent';
import { Box, Chip, CircularProgress, Tab, Tabs } from '@mui/material';
import { useCart } from '../../hook/useCart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { IOrder } from '../../interfaces/IOrder';
import NavBarComponent from '../../components/Widgets/NavBar/NavBarComponent';
import { ICombo } from '../../interfaces/ICombo';
import { IProduct } from '../../interfaces/IProduct';
import { IGroup } from '../../interfaces/IGroup';


import Logo from '../../assets/doce_receita.png';
import EmptyProducts from '../../assets/nao-encontrado.png';
import { useCompany } from '../../hook/useCompany';
import ProductCardComponent from '../../components/ProductCardComponent/ProductCardComponent';
import ModalProduct from '../../components/ModalProduct/ModalProduct';
import Grupo from '../../components/Grupo/Grupo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Orders() {

    // const [cpf, setCpf] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());

    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const [orders, setOrders] = useState<IOrder[]>([]);

    const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const { userOrder, setUserOrder } = useCart();

    useEffect(() => {

        if (userOrder.length === 11) {
            changeCpf(userOrder);
        }
    }, [])

    async function changeCpf(value: string) {

        setUserOrder(value);

        if (value.length == 11) {
            setIsLoadingData(true);
            try {
                const res = await api.get(`/client/document/${value}/orders`);

                setName(res.data.Nome);
                setCity(res.data.Cidade);

                changeDate(startDate, endDate, value);

            } catch (err) {
                setName('');
                setCity('');
                customToast.error("Cliente não encontrado");
            } finally {
                setIsLoadingData(false);
            }
        }
        else {
            setName('');
            setCity('');
            setOrders([]);
        }
    }

    async function changeDate(startDate: Date, endDate: Date, cpf: string) {

        setStartDate(startDate);
        setEndDate(endDate);

        var formatedStartDate = format(startDate, 'yyyy-MM-dd');
        var formatedEndDate = format(endDate, 'yyyy-MM-dd');

        setIsLoadingData(true);

        try {
            const result = await api.get(`/order/client/${cpf}/start-date/${formatedStartDate}/end-date/${formatedEndDate}`);

            var items: IOrder[] = result.data;

            setOrders(items);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingData(false);
        }
    }



    const [value, setValue] = useState<number>(0);
    const [originalGroups, setOriginalGroups] = useState<(IGroup | ICombo)[]>([]);
    const [groups, setGroups] = useState<(IGroup | ICombo)[]>([]);

    const [filterName, setFilterName] = useState<string>('');

    const { company } = useCompany();
    const { setCartType } = useCart();

    var currentIndex: number = 0;


    useEffect(() => {
        setCartType("Encomenda");
        loadGroups();
        // verifyIfIsOpen();
    }, [])

    useEffect(() => {
        filterProductName();
    }, [filterName, value]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function handleTabs() {
        var posicaoScroll = window.scrollY;


        var elementos: NodeListOf<HTMLDivElement> = document.querySelectorAll('.app-items-tab-content');

        elementos.forEach((elemento: HTMLDivElement) => {
            var elementoTopo = elemento.offsetTop;

            // Verifica se o centro do elemento está visível na tela
            if (posicaoScroll >= elementoTopo - 100) {

                elemento.classList.add('destaque');

                const index: number = +elemento.id;

                if (currentIndex !== index) {
                    currentIndex = index;
                    setValue(index);
                    return;
                }
            } else {
                elemento.classList.remove('destaque');
            }
        });
    }

    window.addEventListener('scroll', handleTabs);

    function verifyIfIsOpen() {
        if (company?.Aberto === 'N') {
            customToast.warning("Loja fechada no momento. Assim que abrirmos, o botão FINALIZAR PEDIDO ficará disponível.");
        }
    }

    async function loadGroups() {
        setIsLoadingData(true);
        try {
            const res = await api.get('/group/encomenda');

            var groups: IGroup[] = res.data;

            // Filtra somente os grupos que possuam produtos
            groups = groups.filter(group => group.products.length > 0);

            setGroups(groups);
            setOriginalGroups(groups);

        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingData(false);
        }
    }


    function filterProductName() {
        const valorDigitado = filterName.toLowerCase();

        if (originalGroups.length > 0) {

            // Filtra os grupos conforme valor digitado
            const filteredGroups: (IGroup | ICombo)[] = originalGroups.map(grupo => ({
                ...grupo,
                products: grupo.products?.filter(produto => produto.Descricao.toLowerCase().includes(valorDigitado.toLowerCase())),
            }));

            // Remove os grupos que tiverem sem produtos
            const items = filteredGroups.filter(groups => groups.products && groups.products.length > 0);


            setGroups([...items]);
        }
    }

    function clearFilter() {
        setFilterName('');
    }

    function selectProduct(product: IProduct) {
        setSelectedProduct(product);
        setOpenDialog(true);
    }

    function closeDialog() {
        setSelectedProduct(null);
        setOpenDialog(false);
    }

    return (

        <div className="app-container">
            <NavBarComponent currentScreen="Encomenda" />
            <div className="kaelex-main">
                <div className='kaelex-main-header'>
                    <div className='kaelex-main-header-first-group'>
                        <div>
                            <div className='kaelex-main-header-logo'>
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                        <div className='kaelex-main-header-info'>
                            <div className='kaelex-main-header-info-head'>
                                <div className='kaelex-main-header-info-title'>{company?.Fantasia}</div>
                                {
                                    company?.Aberto === "S" ?
                                        <Chip size='small' label="Aberto para pedidos" sx={{ backgroundColor: '#27ae60', color: 'white' }} />
                                        :
                                        <Chip size='small' label="Fechado para pedidos" sx={{ backgroundColor: '#d01b22', color: 'white' }} />

                                }
                            </div>
                            <div className='kaelex-main-header-info-address'>{company?.Endereco}</div>
                            <div className='kaelex-main-header-info-status'>
                                <div className='kaelex-main-header-info-status-item'>
                                    <div className='kaelex-main-header-info-status-icon'><i className="fa-solid fa-location-dot"></i></div>
                                    {company?.Cidade} - {company?.UF}
                                </div>&#x2022;
                                <div className='kaelex-main-header-info-status-item'>
                                    <div className='kaelex-main-header-info-status-icon'><i className="fa-solid fa-phone"></i></div>
                                    {company?.telefone}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    isLoadingData
                        ?
                        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <div className='group-tabs'>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="lab API tabs example"
                                        TabIndicatorProps={{ style: { backgroundColor: '#743821' } }}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        {
                                            groups.map((group, index) => {
                                                return "Id" in group ?
                                                    <Tab key={`group-${group.Id}`} label={(group.Descricao).toLowerCase()} {...a11yProps(group.Id)} sx={{ color: '#743821 !important', textTransform: 'capitalize', fontWeight: '700', fontSize: 14, lineHeight: '14px', letterSpacing: '0.4px', fontFamily: 'Inter' }} href={`#${index}`} />
                                                    :
                                                    <Tab key={`combo-${group.ID}`} label={(group.Nome).toLowerCase()} {...a11yProps(group.ID)} sx={{ color: '#743821 !important', textTransform: 'capitalize', fontWeight: '700', fontSize: 14, lineHeight: '14px', letterSpacing: '0.4px', fontFamily: 'Inter' }} href={`#${index}`} />

                                            })
                                        }
                                    </Tabs>
                                </Box>
                            </div>

                            <div className='products-search'>
                                <div className='products-search-box'>
                                    {
                                        filterName.length > 0 &&
                                        <div className='products-search-box-x' onClick={() => clearFilter()}>
                                            <i className='fa-solid fa-xmark'></i>
                                        </div>
                                    }

                                    <input type='text' value={filterName} placeholder='Pesquise pelo item que deseja...' onChange={e => setFilterName(e.target.value)} />
                                    <i className='fa-solid fa-search'></i>

                                </div>
                            </div>
                            {
                                groups.map((group, index) => {

                                    return "Id" in group ?
                                        <div key={`tabpanel-group-${index}`} className="app-items-tab-content" id={`${index}`}>
                                            {
                                                <>
                                                    {
                                                        group.products.length > 0 &&
                                                        <Grupo grupo={group} selectProduct={selectProduct} />
                                                    }
                                                </>

                                            }

                                        </div>
                                        :
                                        <div key={`tabpanel-group-${index}`} className="app-items-tab-content" id={`${index}`}>
                                            {
                                                <>
                                                    {
                                                        group.products.length > 0 &&
                                                        <Grupo grupo={group} selectProduct={selectProduct} />

                                                    }

                                                </>

                                            }


                                        </div>

                                })
                            }
                        </Box>
                }
            </div>

            {
                selectedProduct &&
                <ModalProduct product={selectedProduct} handleClose={closeDialog} type='Encomenda' open={openDialog} />
            }
        </div>
    )
}