import './index.css'
import Chip from '@mui/material/Chip';
import PaymentIcon from '@mui/icons-material/Payment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { format } from 'date-fns';
import OrderStatusBarComponent from '../OrderStatusBar/OrderStatusBarComponent';
import { IOrder } from '../../interfaces/IOrder';

interface OrderCardProps {
    order: IOrder;
}

export default function OrderCardComponent({ order }: OrderCardProps) {

    function convertMoney(value: number) {
        return Number(value).toFixed(2).replace(".", ",");
    }

    return (
        <div className='order-card'>
            <div className='order-card-title'>#{order.Id}</div>
            <div className='order-card-status'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-spinner"></i>
                    </div>
                    Status do pedido
                </div>
                <OrderStatusBarComponent status={order.Situacao} />
            </div>
            {
                order.AgEntrega === "S" &&
                <div className='order-card-info'>
                    <div className='order-card-subtitle'>
                        <div className='order-card-subtitle-icon'>
                            <i className="fas fa-receipt"></i>
                        </div>
                        Horário marcado
                    </div>
                    <div className='order-card-info-content'>
                        <Chip icon={<AccessTimeIcon />} label={(order.HoraEntrega).toString().slice(0, 5)} variant="outlined" />
                    </div>
                </div>
            }
            <div className='order-card-info'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-receipt"></i>
                    </div>
                    Informações do pedido
                </div>
                <div className='order-card-info-content'>
                    <Chip icon={<CalendarMonthIcon />} label={format(new Date(order.Data).setUTCHours(24), 'dd/MM/yyyy').toString()} variant="outlined" />
                    <Chip icon={<AccessTimeIcon />} label={(order.Hora).toString().slice(0, 5)} variant="outlined" />
                    <Chip icon={<PaymentIcon />} label={order.tipo_pagto === "O" ? "Online" : order.FormaPagto == "D" ? "Dinheiro" : "Cartão"} variant="outlined" />
                </div>
            </div>
            <div className='order-card-items'>
                <div className='order-card-subtitle'>
                    <div className='order-card-subtitle-icon'>
                        <i className="fas fa-shopping-bag"></i>
                    </div>
                    Itens do pedido
                </div>
                <div className='order-card-items-container'>
                    {order.order_items.map(orderItem => {
                        return (
                            <div className='order-card-items-container-item' key={orderItem.Id}>
                                <div className='order-card-items-container-item-info'>
                                    <div>
                                        {
                                            orderItem?.FatorPeso && orderItem?.FatorPeso > 0 ?
                                                <>
                                                    <b>{(orderItem?.QtdeProduto * orderItem?.FatorPeso).toFixed(3)}kg</b> {(orderItem.product?.Descricao)?.toLowerCase()}
                                                </>
                                                :
                                                <>
                                                    <b>{orderItem?.QtdeProduto}x</b> {(orderItem.product?.Descricao)?.toLowerCase()}
                                                </>
                                        }
                                    </div>
                                    {
                                        orderItem.Obs &&
                                        <div className='order-card-items-container-item-info-observation'>
                                            <div>Observação:</div>
                                            <div>{orderItem.Obs}</div>
                                        </div>
                                    }
                                    <div className='order-card-items-container-item-price'>
                                        {
                                            orderItem?.FatorPeso && orderItem?.FatorPeso > 0
                                                ?
                                                <>
                                                    R$ {convertMoney(orderItem.ValorUnitario * orderItem.QtdeProduto)}
                                                </>
                                                :
                                                <>
                                                    R$ {convertMoney(orderItem.ValorUnitario * orderItem.QtdeProduto)}
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='order-card-items-container-item-info-image'>
                                    <div className='order-card-items-container-item-image'>
                                        {
                                            orderItem?.product?.Imagem !== null && orderItem?.product?.Imagem !== '' && orderItem?.product?.Imagem !== undefined
                                            &&
                                            <img src={`${process.env.REACT_APP_IMAGE_URL}/${(orderItem?.product?.Imagem)?.split(',')[0]}`} />

                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='order-card-items-container-item'>
                        <div>Subtotal</div>
                        <div>{`R$ ${convertMoney(order.ValorTotal - order.ValorFrete)}`}</div>
                    </div>
                    <div className='order-card-items-container-item'>
                        <div>Taxa de entrega</div>
                        <div>{`R$ ${convertMoney(order.ValorFrete)}`}</div>
                    </div>
                    <div className='order-card-items-container-item'>
                        <div> <b>Total</b></div>
                        <div> <b>{`R$ ${convertMoney(order.ValorTotal)}`}</b></div>
                    </div>
                </div>
            </div>
        </div>
    )
}