import { Box, Button, CircularProgress, MenuItem, Step, StepLabel, Stepper, styled, TextField, Tooltip, Alert, AlertTitle, Zoom, Chip, Switch, Select, FormControl, InputLabel } from "@mui/material";
import { KeyboardEvent, useEffect, useState } from "react";
import { useCart } from "../../hook/useCart";
import CartProductComponent from "../CartProduct/CartProductComponent";
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { StepIconProps } from '@mui/material/StepIcon';
import './index.css'
import { api } from "../../lib/api";
import customToast from "../../toast/customToast";
import { LoadingButton } from "@mui/lab";
import FinishedOrderComponent from "../FinishedOrder/FinishedOrderComponent";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IMaskInput } from "react-imask";
import IntlCurrencyInput, { IntlFormatterConfig } from "react-intl-currency-input";
import { useCompany } from "../../hook/useCompany";
import { add, addMinutes, differenceInMinutes, format } from "date-fns";
import { ptBR as ptBRfns } from "date-fns/locale";
import { ptBR } from '@mui/x-date-pickers/locales';
import { HorizontalSplitRounded } from "@mui/icons-material";
import { formatDate, getDateTimeFromString, validaCpf } from "../../utils/utils";
import MaskedInput from "../Widgets/MaskedInput/MaskedInput";

import { cpfMask } from '../../helpers/CpfMask';
import { cepMask } from '../../helpers/CepMask';
import { cardMask } from "../../helpers/CardMask";
import { monthYearMask } from "../../helpers/MonthYearMask";
import { securityCodeMask } from "../../helpers/SecurityCodeMask";
import { encryptCardPagSeguro } from "../../helpers/EncryptPagSeguro.js";
import InputLoading from "../InputLoading/InputLoading";
import axios from "axios";
import { AppError } from "../../errors/AppError";
import { IOrder } from "../../interfaces/IOrder";
import { IAgendamento } from "../../interfaces/IAgendamento";
import IPixPaymentResponse from "../../interfaces/Pagseguro/IPixPaymentResponse";
import QRCodePaymentStep from "./QRCodePaymentStep/QRCodePaymentStep";
import { IAdditional } from "../ModalProduct/ModalProduct";
import IFreteBairro from "../../interfaces/IFreteBairro";

interface IUser {
    Id: number;
    CNPJCPF: string;
    Nome: string;
    Ativo: string;
    CEP: number;
    Endereco: string;
    Numero: string;
    Complemento: string;
    Bairro: string;
    Cidade: string;
    UF: string;
    Tel1: string;
    Tel2: string;
    Email: string;
    idEmpresa: number;
    idcidade?: number;
}

interface IUserCreate {
    CNPJCPF?: string;
    Nome: string;
    Ativo: string;
    Tel1: string;
    idEmpresa: number;
}

interface ICompany {
    Id: number;
    Razao: string;
    Fantasia: string;
    CNPJCPF: string;
    Cidade: string;
    UF: string;
    Status: string;
    Logo: string;
    Email: string;
    Tema: string;
    Endereco: string;
    CEP: string;
    Aberto: string;
    NomeCombo: string;
    HorarioMinimoRetirada?: Date;
    HorarioMaximoRetirada?: Date;
    MensagemFinalPedido?: string;
    MensagemWhatsapp?: string;
    CampoMensagemWhatsapp?: string;
    NumeroWhatsapp?: string;
    corPrimaria?: string;
    corSecundaria?: string;
    corTerciaria?: string;
}

interface IOrderItemComboSendToBackend {
    IdProduto: number;
    ex: number;
    idEmpresa: number;
}

interface IOrderItemSendToBackend {
    QtdeItens: number;
    QtdeProduto: number;
    ValorUnitario: string;
    idEmpresa: number;
    ex: number;
    IdProduto: number;
    FatorPeso?: number;
    Obs?: string;
    adicionais?: IAdditional[];
    orderItemsCombo: IOrderItemComboSendToBackend[];
}

interface IOrderSendToBackend {
    IdCliente: number;
    CEPEntrega?: string;
    EnderecoEntrega?: string;
    NumeroEntrega?: string;
    ComplementoEntrega?: string;
    BairroEntrega?: string;
    CidadeEntrega?: string;
    UFEntrega?: string;
    Data: Date;
    Hora: string | Date;
    HoraEntrega: string | Date;
    AgEntrega: string;
    Situacao: string;
    ValorFrete: number;
    ValorTotal: number;
    Troco: number;
    idEmpresa: number;
    TipoEntrega: string;
    FormaPagto: string;
    idcidade?: number;
    orderItems: IOrderItemSendToBackend[];
    tipoPagamento: string;
    tipo_pagto: string;
    Obs?: string;
    CPFcupom: "S" | "N";
    cpf?: string;
}

interface IAgendamentoItemComboSendToBackend {
    IdProduto: number;
    ex: number;
    idEmpresa: number;
}

interface IAgendamentoSendToBackend {
    Id: number;
    idEmpresa: number;
    idCliente: number;
    CEPEntrega: string;
    EnderecoEntrega: string;
    NumeroEntrega: string;
    ComplementoEntrega?: string;
    BairroEntrega: string;
    CidadeEntrega: string;
    UFEntrega: string;
    Data: string;
    Hora: string;
    IdCliente: number;
    Valor: string;
    ValorFrete: number;
    Delivery: string;
    Obs?: string;
    Qtd: string;
    Status: "A" | "C" | "G";
    EndEntrega: string;
    nomeusu: string;
    TipoPag: string;
    TipoPagEntrega: string;
    PagEntregaTroco: string;
    HoraMarcada: string;
    TaxaDelivery?: string;
    DtEntrega?: string;
    HrEntrega?: string;
    DtAgendada?: string;
    HrAgendada?: string;
    agendamento_i: IAgendamentoISendToBackend[];
    tipoPagamento: string;
    CPFcupom: "S" | "N";
    cpf?: string;
}

interface IAgendamentoISendToBackend {
    Id?: number;
    IdAg?: number;
    IdProduto: number;
    Qtd: number;
    Unit: string;
    Total: number;
    FatorPeso: null | number;
    adicionais?: IAdditional[];
    agendamento_i_combo: IAgendamentoItemComboSendToBackend[];
    OBS?: string;
}

interface IVendaResponse {
    status: string;
    code: number;
    idVenda?: number;
}

interface IAgendamentoItemComboSendToBackend {
    IdProduto: number;
    ex: number;
    idEmpresa: number;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#743821',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#743821',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#743821',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#743821',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
);

const currencyConfig: IntlFormatterConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

export default function StepperCartComponent() {
    const steps = ['Carrinho', 'Identificação', 'Revisão', 'Finalizar'];
    const [activeStep, setActiveStep] = useState(0);

    const [client, setClient] = useState<IUser | null>(null);
    const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);
    const [deliveryType, setDeliveryType] = useState<string>("D");
    const [paymentMethod, setPaymentMethod] = useState<string>("D");
    const [freight, setFreight] = useState<number | null>(null);
    const [needChangeMoney, setNeedChangeMoney] = useState<boolean>(false);
    const [changeMoney, setChangeMoney] = useState<number>(0);
    const [cpf, setCpf] = useState('');
    const [cpfCartao, setCpfCartao] = useState('');
    const [cpfNaNota, setCpfNaNota] = useState<boolean>(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [orderObservation, setOrderObservation] = useState("");
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState<boolean>(false);
    const [email, setEmail] = useState('');
    const [markedHour, setMarkedHour] = useState<Date | null>(new Date());
    const [dataEntrega, setDataEntrega] = useState<Date | null>(null);
    const [horaEntrega, setHoraEntrega] = useState<Date | null>(null);
    const [dataRetirada, setDataRetirada] = useState<Date | null>(null);
    const [horaRetirada, setHoraRetirada] = useState<Date | null>(null);
    const [isIncorrectDate, setIsIncorretDate] = useState<boolean>(false);
    const [isIncorrectHour, setIsIncorretHour] = useState<boolean>(false);
    const [tipoPagamento, setTipoPagamento] = useState<("Local" | "Online")>("Local");

    const [cardNumber, setCardNumber] = useState("");
    const [cardDate, setCardDate] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [cardCostumer, setCardCustomer] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorCardPayment, setShowErrorCardPayment] = useState(false);
    const [encryptedCard, setEncryptedCard] = useState(null);
    const [metodoPagamento, setMetodoPagamento] = useState<("Pix" | "Cartão")>("Cartão");

    const [cpfError, setCpfError] = useState<boolean>(false);
    const [titularError, setTitularError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);

    const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false);

    const [bairros, setBairros] = useState<IFreteBairro[]>([]);

    const [cep, setCep] = useState<string>('');
    const [cidade, setCidade] = useState<string>('');
    const [uf, setUf] = useState<string>('');
    const [logradouro, setLogradouro] = useState<string>('');
    const [numero, setNumero] = useState<string>('');
    const [bairro, setBairro] = useState<string>('');
    const [complemento, setComplemento] = useState<string>('');

    const [pixPaymentResponse, setPixPaymentResponse] = useState<IPixPaymentResponse | null>(null);

    const [createdPayment, setCreatedPayment] = useState<IOrder | IAgendamento | undefined | null>(null);

    const { cartProducts, cartType, deleteCart, isGettingPublicKey, isSendingOrder, setIsGettingPublicKey, setIsSendingOrder } = useCart();
    const { company, aberto } = useCompany();

    useEffect(() => {
        setHoraEntrega(null);
        setDataEntrega(null);
    }, [dataRetirada, deliveryType]);

    useEffect(() => {
        setDataRetirada(null);
        setHoraRetirada(null);
    }, [dataEntrega, deliveryType]);

    useEffect(() => {
        if (company?.tipo_taxa_entrega !== "C") {
            if (cidade != '' && uf != '') {
                calcFreight(deliveryType);
            }
        }
    }, [cidade, uf])

    useEffect(() => {
        if (company?.tipo_taxa_entrega === "C") {
            listBairrosByCidade();
        }
    }, [cidade])

    useEffect(() => {
        if (company?.tipo_taxa_entrega === "C") {
            if (cidade != '' && uf != '') {
                calcFreight(deliveryType);
            }
        }
    }, [bairro])

    function getDateFromHours(time: string) {
        let splitedTime = time.split(':').map(Number);
        let now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...splitedTime);
    }

    async function handleNextIdentification() {

        setPhoneError(false);
        setNameError(false);

        if (phone.length < 10) {
            setPhoneError(true);
            return;
        }

        if (name.length < 1) {
            setNameError(true);
            return;
        }

        if (cpfNaNota) {
            if (!validaCpf(removeMask(cpf))) {
                customToast.error("CPF informado é inválido.");
                return;
            }

        }

        if (client) {

            handleNext();
        } else {
            const created = await createClient();

            if (created) {
                handleNext();
            }
        }

    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleBackCardInfo = () => {
        setActiveStep(2);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleBackIdentification = () => {
        setActiveStep(1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleReset = async () => {
        await deleteCart();
        setActiveStep(0);
    };

    function maskTel(value: string) {
        const telFormatado = removeMask(value);
        return telFormatado.length > 10 ? '(00)00000-0000' : '(00)0000-00000';
    }

    async function findAddressByCep(e: KeyboardEvent<HTMLInputElement>) {
        var value = (e.target as HTMLInputElement).value;

        if (value.length === 9) {

            const cepSemMascara = removeMask(value);

            const result = await axios.get(`https://viacep.com.br/ws/${cepSemMascara}/json/`);

            if (result.data.erro) {
                customToast.error("CEP Inválido");
                setFreight(null);
                setCidade('');
                setUf('');
                setLogradouro('');
                setNumero('');
                setBairro('');

                return;
            }

            if (company?.tipo_taxa_entrega !== 'C') {
                if (result.data.bairro) {
                    setBairro(result.data.bairro);
                } else {
                    setBairro('');
                }
            }

            result.data.localidade ? setCidade(result.data.localidade) : setCidade('');
            result.data.logradouro ? setLogradouro(result.data.logradouro) : setLogradouro('');
            result.data.uf ? setUf(result.data.uf) : setUf('');
            result.data.localidade ? setCidade(result.data.localidade) : setCidade('');
        }
        else {
            setCidade('');
            setUf('');
            setLogradouro('');
            setBairro('');
            setFreight(null);
        }
    }

    async function createClient() {

        if (cpf.length == 14) {
            if (!validaCpf(removeMask(cpf))) {
                customToast.error("O CPF informado é inválido");
                return false;
            }
        }

        if (name.length < 1) {
            customToast.error("O campo nome é obrigatório");
            return false;
        }

        if (phone.length < 10) {
            customToast.error("Telefone inválido");
            return false;
        }

        const data: IUserCreate = {
            Nome: name,
            idEmpresa: process.env.REACT_APP_ID_EMPRESA ? +process.env.REACT_APP_ID_EMPRESA : 0,
            Tel1: removeMask(phone),
            Ativo: 'S'
        }

        if (cpf.length > 0) {
            data.CNPJCPF = removeMask(cpf);
        }

        setIsCreatingClient(true);

        try {
            const result = await api.post('/client', data);

            setClient(result.data);

            return true;

        } catch (err: any) {

            customToast.error("Houve um erro na identificação, por favor tente novamente!");
            return false;

        } finally {
            setIsCreatingClient(false);
        }
    }

    async function getEncryptCard() {

        let error = 0;
        if (cardNumber.length < 19) {
            error++;
        } else if (cardDate.length < 7) {
            error++;
        } else if (cardCostumer.length <= 0) {
            error++;
        } else if (cpfCartao.length < 14) {
            error++;
        } else if (securityCode.length <= 0) {
            error++;
        }

        setIsGettingPublicKey(true);

        if (error == 0) {
            let result = await api.post('/getPublicKey', { tokenCartao: `${company?.pagseguro_token_pagamento}` });

            let res = result.data;

            if (res.status == 200) {

                let dados = JSON.parse(res.data);

                let expMonth = cardDate.split("/")[0];
                let expYear = cardDate.split("/")[1];

                let data = {
                    publicKey: dados.public_key,
                    holder: cardCostumer,
                    number: cardNumber.replaceAll(/\s/g, ''),
                    expMonth: expMonth,
                    expYear: expYear,
                    securityCode: securityCode
                }

                let cardData = encryptCardPagSeguro(data);

                if (cardData.hasErrors && cardData.errors.length > 0) {
                    cardData.errors.map(function (i: any, obj: any) {
                        setShowErrorMessage(true);
                        setIsGettingPublicKey(false);
                        // if (obj.code === "INVALID_NUMBER") {
                        //     alert("Número do cartão inválido!\n\nPor favor, verifique se número está preenchido corretamente.");
                        // }
                        // if (obj.code === "INVALID_SECURITY_CODE") {
                        //     alert("Código de segurança inválido!\n\nPor favor, verifique se número está preenchido corretamente.");
                        // }
                        // if (obj.code === "INVALID_EXPIRATION_MONTH") {
                        //     alert("Mês de expiração inválido!\n\nPor favor, verifique se número está preenchido corretamente.");
                        // }
                        // if (obj.code === "INVALID_EXPIRATION_YEAR") {
                        //     alert("Ano de expiração inválido!\n\nPor favor, verifique se número está preenchido corretamente.");
                        // }
                        // if (obj.code === "INVALID_PUBLIC_KEY") {
                        //     alert("Chave de pagamento inválida!\n\nErro ao realizar pagamento, tente novamente em instantes.");
                        // }
                        // if (obj.code === "INVALID_HOLDER") {
                        //     alert("Nome do proprietário do cartão inválido!\n\nPor favor, verifique se está preenchido corretamente.");
                        // }
                    });
                } else {
                    setShowErrorMessage(false);
                    setEncryptedCard(cardData.encryptedCard);
                    setIsGettingPublicKey(false);
                    // handleNext();
                    handleCardPayment(cardData.encryptedCard);
                }
            }
        } else {
            setShowErrorMessage(true);
            setIsGettingPublicKey(false);
        }
    }

    const handleCardPayment = async (encryptedCardProp: any) => {

        // Verifica se o pedido já foi criado (Caso o pagamento tenha sido recusado), aí só faz o pagamento sem criar um pedido novo
        if (createdPayment) {
            sendCardPayment(createdPayment, encryptedCardProp);
        } else { //Cria um novo pedido
            try {
                //Insere a venda pronta entrega ou agendamento
                let resVenda;
                if (cartType == 'Pronta Entrega') {
                    resVenda = await sendOrder();
                } else {
                    resVenda = await sendAgendamento();
                }

                setCreatedPayment(resVenda);

                if (resVenda === undefined) {
                    return;
                }

                sendCardPayment(resVenda, encryptedCardProp);

            } catch (err) {
                AppError(err);
                setShowErrorCardPayment(true);
                setIsSendingOrder(false);
            }
        }


    }

    async function sendCardPayment(venda: (IOrder | IAgendamento), encryptedCardProp: string) {
        try {
            let dataPost = {
                encryptedCard: encryptedCardProp,
                tokenCartao: company?.pagseguro_token_pagamento,
                total: calcTotalCartValue(),
                cpf: cpfCartao,
                nomeCliente: cardCostumer,
                idVenda: venda.Id,
                tipoVenda: cartType,
                email: email
            }

            setIsSendingOrder(true);

            //Tenta realizar o pagamento
            let resPayment = await api.post('/cardPayment', dataPost);

            let statusPayment = resPayment.data.paymentStatus;

            //Verifica se o pagamento foi concluído com sucesso
            if (resPayment.data.status == 200) {
                if (statusPayment == 'PG') {

                    customToast.success("Pedido realizado com sucesso!");

                    localStorage.removeItem('app_items');

                    handleNext();

                    setIsSendingOrder(false);
                } else {
                    customToast.error("Houve um erro ao realizar o pagamento, por favor verifique as informações preenchidas!");
                    setIsSendingOrder(false);
                    // handleBackCardInfo();
                }
            } else {
                customToast.error("Houve um erro ao realizar o pagamento, por favor verifique as informações preenchidas!");
                setIsSendingOrder(false);
                // handleBackCardInfo();
            }
        } catch (err) {
            AppError(err);
            setShowErrorCardPayment(true);
            setIsSendingOrder(false);
        }
    }

    const handlePixPayment = async () => {
        try {
            let resVenda;
            if (cartType == 'Pronta Entrega') {
                resVenda = await sendOrder();
            } else {
                resVenda = await sendAgendamento();
            }

            if (resVenda === undefined) {
                return;
            }

            const data = {
                paymentToken: company?.pagseguro_token_pagamento,
                total: calcTotalCartValue(),
                cpf: cpfCartao,
                nomeCliente: cardCostumer,
                idVenda: resVenda.Id,
                tipoVenda: cartType,
                email: email
            }

            setIsSendingOrder(true);

            const payment: { data: IPixPaymentResponse } = await api.post('/pixPayment', data);

            setPixPaymentResponse(payment.data);


        } catch (err) {
            AppError(err);
        } finally {
            setIsSendingOrder(false);
        }
    }

    // const handlePixPayment = async () => {

    //     setIsLoadingCompra(true);

    //     try {
    //         const res = await api.postSell(products, token);

    //         if (res.code == 200) {
    //             if (paymentMethod == 'pix') {

    //                 setIdVenda(res.idVenda);

    //                 setMsgButton("Gerando QR Code...");
    //                 await getQrcodePix(res.idVenda);

    //                 echo.channel(`paymentEvent-${res.idVenda}`)
    //                     .listen(`PaymentEvent`, (result) => {
    //                         console.log('aqui');
    //                         if (result.data.status === 'A') {
    //                             setSucessPixPayment(true);
    //                         }
    //                     });
    //             }

    //         }
    //     } catch (err) {
    //         console.log(err);
    //     } finally {
    //         setIsLoadingCompra(false);
    //         setMsgButton("Prosseguir");
    //     }

    // }

    // const getQrcodePix = async (idPedido) => {
    //     const res = await api.getQrcodePix(idPedido, tokenPagamento, email);

    //     if(res.status == 200){
    //         let data = JSON.parse(res.data);

    //         setQrcodePix(data.qr_codes[0].links[0].href);
    //         setTextPix(data.qr_codes[0].text);
    //         setExpirationDateQrCodePix(data.qr_codes[0].expiration_date);
    //     }else{
    //         //Toast message que deu errado a geração do qrcode do pix
    //     }

    // }

    // function copyToClipboard() {
    //     // Get the text field
    //     var copyText = document.getElementById("qrcode-input");

    //     // Select the text field
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999); // For mobile devices

    //     // Copy the text inside the text field
    //     navigator.clipboard.writeText(copyText.value);
    // }

    function getHorarioAbertura(date: Date | null) {
        if (company) {
            const diaSemana = date?.getDay();

            switch (diaSemana) {
                case 0:
                    return company.AbreDomingo;
                    break;
                case 1:
                    return company.AbreSegunda;
                    break;
                case 2:
                    return company.AbreTerca;
                    break;
                case 3:
                    return company.AbreQuarta;
                    break;
                case 4:
                    return company.AbreQuinta;
                    break;
                case 5:
                    return company.AbreSexta;
                    break;
                case 6:
                    return company.AbreSabado;
                    break;
                default:
                    return undefined;
            }
        }
        return undefined;
    }

    function getHorarioFechamento() {
        if (company) {
            const diaSemana = dataRetirada?.getDay();

            switch (diaSemana) {
                case 0:
                    return company.FechaDomingo;
                    break;
                case 1:
                    return company.FechaSegunda;
                    break;
                case 2:
                    return company.FechaTerca;
                    break;
                case 3:
                    return company.FechaQuarta;
                    break;
                case 4:
                    return company.FechaQuinta;
                    break;
                case 5:
                    return company.FechaSexta;
                    break;
                case 6:
                    return company.FechaSabado;
                    break;
                default:
                    return undefined;
            }
        }
        return undefined;
    }

    function getHorarioAberturaRetirada(date: Date | null) {
        if (company) {
            return company.HorarioMinimoRetirada;
        }
        return undefined;
    }

    function getHorarioFechamentoRetirada() {
        if (company) {
            return company.HorarioMaximoRetirada;
        }
        return undefined;
    }

    function getDataEHoraAbertura() {
        if (deliveryType === "D") {
            return getDateTimeFromString(formatDate(dataEntrega), getHorarioAbertura(dataEntrega) ?? '10:00:00');
        } else {
            return getDateTimeFromString(formatDate(dataRetirada), getHorarioAberturaRetirada(dataRetirada) ?? '10:00:00');
        }
    }
    function getDataEHoraFechamento() {

        if (deliveryType === "D") {
            return getDateTimeFromString(formatDate(dataEntrega), getHorarioFechamento() ?? '18:00:00');
        } else {
            return getDateTimeFromString(formatDate(dataRetirada), getHorarioFechamentoRetirada() ?? '18:00:00');
        }
    }

    // Essa função irá definir o horário que pode ser realizado o primeiro agendamento considerando o campo AgenAntecedencia
    function getDataEHoraParaPrimeiroPedidoAgendamento() {
        // Se o cliente selecionar "delivery" pega a data de entrega se não pega a data de retirada
        const dataToCompare = deliveryType === "D" ? format(dataEntrega ?? new Date(), 'yyyy-MM-dd') : format(dataRetirada ?? new Date(), 'yyyy-MM-dd');

        var minutosPassados = 0; //Armazena a quantidade de tempo em minutos que a loja abriu
        var tempoAntecedenciaAgen = company?.AgenAntecedencia ?? 0; // Tempo de antecedência necessario para agendamento

        // Verifica se a data de entrega/retirada selecionada é hoje
        if ((format(new Date(), 'yyyy-MM-dd') === dataToCompare)) {

            const diferencaDaHoraAtualParaAHoraAberturaEmMinutos = differenceInMinutes(new Date(), getDataEHoraAbertura()); // Calcula a diferença em minutos da hora atual pro horario de abertura

            // Verifica se o horario atual é depois ou antes da loja abrir
            if (diferencaDaHoraAtualParaAHoraAberturaEmMinutos > 0) {
                minutosPassados = diferencaDaHoraAtualParaAHoraAberturaEmMinutos;
            }

            // Adiciona os minutos necessários para selecionar o primeiro horário
            return addMinutes(getDataEHoraAbertura(), (Number(minutosPassados) + Number(tempoAntecedenciaAgen)));
        }

        return getDataEHoraAbertura();
    }

    function calcTotalCartValue() {
        let totalValue = 0;

        cartProducts.forEach(product => {

            if (product.fatorPeso > 0) {

                totalValue += product.price * product.fatorPeso;
            }
            else {
                totalValue += product.price;
            }

        })

        return totalValue;
    }

    async function calculateDistance(): Promise<number> {
        if (company) {

            const google = window.google;

            const directionsService = new google.maps.DirectionsService();

            const origin = `${company.Endereco}, ${company.Cidade}-${company.UF}, ${company.CEP}`;
            const destination = `${logradouro}, ${bairro}, ${cidade}-${uf}, ${cep}`; // Substitua destLatitude e destLongitude pelos valores desejados.

            const directionsRequest = {
                origin,
                destination,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
            };


            try {
                const res = await directionsService.route(directionsRequest, function (response, status) {
                    if (response) {
                        if (status === google.maps.DirectionsStatus.OK && response.routes[0]?.legs[0]?.distance) {
                            const distanceInKilometers = response.routes[0].legs[0].distance.value / 1000;
                            console.log(`Distância: ${distanceInKilometers} km`);
                        } else {
                            console.log("Não foi possível calcular a distância.");
                        }
                    }
                    else {
                        // console.log("Deu erro");
                    }

                });

                // console.log(Number(res.routes[0].legs[0].distance?.value) / 1000);

                return Number(res.routes[0].legs[0].distance?.value) / 1000;

            } catch (error) {
                console.error("Ocorreu um erro ao calcular a distância:", error);
            }
        }


        return -1;


    }

    async function calcFreight(deliveryType: string) {
        let freightResult: any;
        let cepFreightResult: any;

        if (deliveryType === "D" || deliveryType === "R" || deliveryType === "M") {
            if (deliveryType === "D") {

                const distancia = await calculateDistance();

                if (company?.DeliveryKmMaximo && distancia > company?.DeliveryKmMaximo) {
                    customToast.warning("Limite de distância excedido");
                    setFreight(null);
                } else {
                    if (company?.tipo_taxa_entrega === "C") { // Calcula a taxa de entrega com base no CEP
                        if (bairro !== '') {
                            setIsLoadingClient(true);
                            try {
                                freightResult = await api.get(`/district-freight/district/${bairro}/cidade/${cidade}/company/${process.env.REACT_APP_ID_EMPRESA}`);

                                setFreight(Number(freightResult.data.Valor));
                            } catch (err) {
                                console.clear();
                                try {
                                    cepFreightResult = await api.get(`/cep-freight/cep/${removeMask(cep)}/company/${process.env.REACT_APP_ID_EMPRESA}`);

                                    setFreight(Number(cepFreightResult.data.Valor));
                                } catch (err) {
                                    console.clear();
                                    setFreight(null)
                                }

                            } finally {
                                setIsLoadingClient(false);
                            }
                        }
                    } else if (company?.tipo_taxa_entrega === "F") { // Calcula a taxa de entrega com base na faixa de KM

                        if (distancia != -1) {
                            let taxa: number = 0;

                            if (company?.KM_1 && company?.KM_1_Taxa && company?.KM_1 > distancia) {
                                taxa = +company.KM_1_Taxa;
                            }
                            else if (company?.KM_2 && company?.KM_2_Taxa && company?.KM_2 > distancia) {
                                taxa = +company.KM_2_Taxa;
                            }
                            else if (company?.KM_3 && company?.KM_3_Taxa && company?.KM_3 > distancia) {
                                taxa = +company.KM_3_Taxa;
                            }
                            else if (company?.KM_4 && company?.KM_4_Taxa && company?.KM_4 > distancia) {
                                taxa = +company.KM_4_Taxa;
                            }
                            else if (company?.KM_5 && company?.KM_5_Taxa && company?.KM_5 > distancia) {
                                taxa = +company.KM_5_Taxa;
                            }
                            else if (company?.KM_6 && company?.KM_6_Taxa) {
                                taxa = +company.KM_6_Taxa;
                            } else {
                                customToast.error("Delivery indisponível nesse endereço");
                                setFreight(null);
                                return;
                            }

                            setFreight(taxa);
                        } else {
                            setFreight(null);
                        }
                    } else if (company?.tipo_taxa_entrega === "K") { // Calcula a taxa de entrega com base no KM

                        if (distancia != -1) {
                            if (company?.TaxaEntregaKM) {
                                setFreight(Number(company.valor_fixo_taxa_entrega) + Number(company.TaxaEntregaKM * distancia));
                            }
                            else {
                                customToast.warning("O estabelecimento não definiu uma taxa de entrega");
                                setFreight(null);
                            }
                        }
                        else {
                            setFreight(null);
                        }
                    }
                }



            }
            else {
                setFreight(0);
            }
        }
    }

    async function listBairrosByCidade() {
        try {
            const result = await api.get(`/district-freight/cidade/${cidade}`);

            setBairros(result.data);
        } catch (err) {
            AppError(err);
        }
    }

    async function loadClientByCpf(cpf: string) {
        setCpf(cpf);

        cpf = removeMask(cpf);

        if (cpf.length === 11) {
            setIsLoadingClient(true);
            try {
                const res = await api.get(`/client/document/${cpf}`)

                setClient(res.data);
                setName(res.data.Nome);
                setPhone(res.data.Tel1);
            }
            catch (err) {
                console.clear();
                setClient(null);
            } finally {
                setIsLoadingClient(false);
            }
        }
        else {
            setClient(null);
        }
    }

    async function loadClientByPhone(value: string) {

        setPhone(value);

        value = removeMask(value);

        if (value.length === 11) {
            setIsLoadingClient(true);
            try {
                const res = await api.get(`/client/phone/${value}`)

                setName(res.data.Nome);
                setClient(res.data);
            }
            catch (err) {
                console.clear();
                setClient(null);
            } finally {
                setIsLoadingClient(false);
            }
        }
        else {
            setClient(null);
        }
    }

    async function sendOrder() {
        setCpfError(false);
        setTitularError(false);
        setEmailError(false);

        if (!client) {
            customToast.error('Não foi encontrar o cliente');
            handleBackIdentification();
            return;
        }

        if (freight === null && deliveryType === "D") {
            customToast.error('Não fazemos entrega nesse endereço');
            handleBackIdentification();
            return;
        }



        if (paymentMethod !== "D") {
            setChangeMoney(0);
        }
        else if (paymentMethod === "D" && !needChangeMoney) {
            setChangeMoney(0);
        }
        else {
            if (isNaN(changeMoney)) {
                customToast.error("O troco deve ser um valor válido");
                handleBack();
                return;
            }
            if (changeMoney < (calcTotalCartValue() + (freight ? freight : 0))) {
                customToast.error("O valor para o troco não pode ser menor que o valor do carrinho");
                handleBack();
                return;
            }
        }



        let items: IOrderItemSendToBackend[] = [];

        cartProducts.forEach(item => {

            items.push({
                QtdeItens: 0,
                QtdeProduto: item.quantity,
                ValorUnitario: item.fatorPeso > 0 ? (item.unit_price * item.fatorPeso).toString() : (item.unit_price).toString(),
                idEmpresa: process.env.REACT_APP_ID_EMPRESA ? +process.env.REACT_APP_ID_EMPRESA : 0,
                ex: 0,
                IdProduto: Number(item.id),
                FatorPeso: item.fatorPeso > 0 ? item.fatorPeso : undefined,
                Obs: item.observation.trim() != "" ? item.observation : undefined,
                adicionais: item.selectedAdditionals.map(additional => {
                    return {
                        id: additional.id,
                        product: additional.product,
                        quantidade: additional.quantidade,
                    }
                }),
                orderItemsCombo: item.orderItemsCombo.map(value => {
                    return {
                        IdProduto: value.productId,
                        ex: 0,
                        idEmpresa: process.env.REACT_APP_ID_EMPRESA ? +process.env.REACT_APP_ID_EMPRESA : 0
                    }
                })
            })
        })

        let HoraEntrega: string | Date = '00:00:00';
        let TipoEntrega = deliveryType;
        let AgEntrega = 'N';

        if (deliveryType === 'M') {

            if (company?.HorarioMinimoRetirada == null || company?.HorarioMaximoRetirada == null) {
                customToast.error("Esse restaurante não permite agendar retirada");
                return;
            }

            if (markedHour == null) {
                customToast.error('Informe um horário para retirada do pedido');
                return;
            }

            HoraEntrega = markedHour.toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" });

            if (getDateFromHours(HoraEntrega) < getDateFromHours(company?.HorarioMinimoRetirada) || getDateFromHours(HoraEntrega) > add(getDateFromHours(company?.HorarioMaximoRetirada), { seconds: 59 })) {
                customToast.error(`O horário de entrega deve ser entre as ${format(getDateFromHours(company?.HorarioMinimoRetirada), 'HH:mm')} e ${format(getDateFromHours(company?.HorarioMaximoRetirada), 'HH:mm')}`);
                return;
            }

            if (add(getDateFromHours(HoraEntrega), { seconds: 59 }) < new Date()) {
                customToast.error(`Por favor, informe um horário maior que o horário atual.`);
                return;
            }

            TipoEntrega = 'R';
            AgEntrega = 'S';
        }

        if (tipoPagamento === "Online") {
            if (metodoPagamento === "Pix") {
                if (!validaCpf(removeMask(cpfCartao))) {
                    customToast.error("CPF informado é inválido");
                    setCpfError(true);
                    return;
                }

                if (cardCostumer.length < 1) {
                    customToast.error("Por favor, informe o nome completo.");
                    setTitularError(true);
                    return;
                }

                if (email.length < 1) {
                    customToast.error("Por favor, preencha o campo email.");
                    setEmailError(true);
                    return;
                }
            }
        }


        const order: IOrderSendToBackend = {
            IdCliente: client.Id,
            CEPEntrega: deliveryType === "D" ? removeMask(cep) : undefined,
            EnderecoEntrega: deliveryType === "D" ? logradouro : undefined,
            NumeroEntrega: deliveryType === "D" ? numero : undefined,
            BairroEntrega: deliveryType === "D" ? bairro : undefined,
            CidadeEntrega: deliveryType === "D" ? cidade : undefined,
            UFEntrega: deliveryType === "D" ? uf : undefined,
            Data: new Date(),
            Hora: new Date().toLocaleTimeString("pt-BR", { timeZone: "America/Sao_Paulo" }),
            HoraEntrega,
            AgEntrega,
            Situacao: "A",
            ValorFrete: (freight ? freight : 0),
            ValorTotal: calcTotalCartValue() + (freight ? freight : 0),
            Troco: changeMoney,
            idEmpresa: process.env.REACT_APP_ID_EMPRESA ? +process.env.REACT_APP_ID_EMPRESA : 0,
            idcidade: client.idcidade ? client.idcidade : undefined,
            TipoEntrega,
            FormaPagto: paymentMethod,
            orderItems: items,
            tipoPagamento: tipoPagamento === 'Local' ? 'L' : 'O',
            tipo_pagto: tipoPagamento === 'Local' ? 'L' : 'O',
            CPFcupom: cpfNaNota ? 'S' : 'N',
        }

        if (deliveryType === "D") {
            if (complemento.length > 0) {
                order.ComplementoEntrega = complemento;
            }
            if (client?.Complemento && client?.Complemento.length > 0) {
                order.ComplementoEntrega = client?.Complemento;
            }
        }

        if (orderObservation.length > 0) {
            order.Obs = orderObservation;
        }

        if (cpfNaNota) {
            if (cpf.length > 0) {
                order.cpf = `${removeMask(cpf)}`;
            }
        }

        setIsSendingOrder(true);

        if (tipoPagamento === 'Online') {

            try {
                const resultOrder: { data: IOrder } = await api.post('/order', order);

                return resultOrder.data;

            } catch (err) {
                AppError(err);
            } finally {
                setIsSendingOrder(false);
            }
        } else {
            try {

                await api.post('/order', order);

                customToast.success("Pedido enviado com sucesso");

                localStorage.removeItem('app_items');

                handleNext();
            } catch (err) {
                AppError(err);
            } finally {
                setIsSendingOrder(false);
            }
        }


    }

    async function sendAgendamento() {

        if (!client) {
            customToast.error('Não foi encontrar o cliente');
            handleBackIdentification();
            return;
        }

        if (freight === null && deliveryType === "D") {
            customToast.error('Não fazemos entrega nesse endereço');
            handleBackIdentification();
            return;
        }

        if (paymentMethod !== "D") {
            setChangeMoney(0);
        }
        else if (paymentMethod === "D" && !needChangeMoney) {
            setChangeMoney(0);
        }
        else {
            if (isNaN(changeMoney)) {
                customToast.error("O troco deve ser um valor válido");
                handleBack();
                return;
            }
            if (changeMoney < (calcTotalCartValue() + (freight ? freight : 0))) {
                customToast.error("O valor para o troco não pode ser menor que o valor do carrinho");
                handleBack();
                return;
            }
        }

        if (deliveryType === 'D') {
            if (dataEntrega === null || horaEntrega === null) {
                customToast.warning("Selecione uma data e hora para entrega/retirada do pedido");
                handleBackIdentification();
                return;
            }
        } else {
            if (dataRetirada === null || horaRetirada === null) {
                customToast.warning("Selecione uma data e hora para retirada do pedido");
                handleBackIdentification();
                return;
            }
        }

        let itensAgendamento: IAgendamentoISendToBackend[] = [];

        cartProducts.map(function (item) {

            itensAgendamento.push({
                IdProduto: Number(item.id),
                Qtd: item.quantity,
                Unit: item.fatorPeso > 0 ? (item.unit_price * item.fatorPeso).toString() : (item.unit_price).toString(),
                Total: item.price,
                FatorPeso: item.fatorPeso > 0 ? item.fatorPeso : null,
                adicionais: item.selectedAdditionals.map(additional => {
                    return {
                        id: additional.id,
                        product: additional.product,
                        quantidade: additional.quantidade,
                    }
                }),
                agendamento_i_combo: item.orderItemsCombo.map(value => {
                    return {
                        IdProduto: value.productId,
                        ex: 0,
                        idEmpresa: process.env.REACT_APP_ID_EMPRESA ? +process.env.REACT_APP_ID_EMPRESA : 0
                    }
                }),
                OBS: item.observation.trim() != "" ? item.observation : undefined
            });
        });



        const data: Partial<IAgendamentoSendToBackend> = {
            "idEmpresa": process.env.REACT_APP_ID_EMPRESA ? Number(process.env.REACT_APP_ID_EMPRESA) : Number(0),
            "idCliente": client.Id,
            "Delivery": deliveryType === "D" ? "S" : "N",
            "CEPEntrega": deliveryType === "D" ? removeMask(cep) : undefined,
            "EnderecoEntrega": deliveryType === "D" ? logradouro : undefined,
            "NumeroEntrega": deliveryType === "D" ? numero : undefined,
            "BairroEntrega": deliveryType === "D" ? bairro : undefined,
            "CidadeEntrega": deliveryType === "D" ? cidade : undefined,
            "UFEntrega": deliveryType === "D" ? uf : undefined,
            "Valor": calcTotalCartValue().toString(),
            "ValorFrete": (freight ? freight : 0),
            "agendamento_i": itensAgendamento.map(val => val),
            "TipoPag": deliveryType === "D" ? "E" : "L",
            "TipoPagEntrega": `${paymentMethod}`,
            "DtEntrega": deliveryType === "D" ? format(dataEntrega ? dataEntrega : 0, 'yyyy-MM-dd') : format(dataRetirada ? dataRetirada : 0, 'yyyy-MM-dd'),
            "HrEntrega": deliveryType === "D" ? format(horaEntrega ? horaEntrega : 0, 'hh:mm:ss') : format(horaRetirada ? horaRetirada : 0, 'hh:mm:ss'),
            "tipoPagamento": tipoPagamento === 'Local' ? 'L' : 'O',
            "CPFcupom": cpfNaNota ? 'S' : 'N'
        }

        if (deliveryType === "D") {
            if (complemento.length > 0) {
                data.ComplementoEntrega = complemento;
            }
        }

        if (orderObservation.length > 0) {
            data.Obs = orderObservation;
        }

        if (cpfNaNota) {
            if (cpf.length > 0) {
                data.cpf = `${removeMask(cpf)}`;
            }
        }


        setIsSendingOrder(true);

        if (tipoPagamento === 'Online') {

            try {
                const resultAgend: { data: IAgendamento } = await api.post('/agendamento', data);

                return resultAgend.data;
            } catch (err) {
                AppError(err);
            } finally {
                setIsSendingOrder(false);
            }
        } else {
            try {

                await api.post('/agendamento', data);

                customToast.success("Pedido enviado com sucesso");

                localStorage.removeItem('app_items');

                handleNext();
            } catch (err) {
                customToast.error("Erro ao enviar o pedido");
            }
        }

        setIsSendingOrder(false);

    }

    function removeMask(value: string) {
        return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
    }

    function formatMoney(value: number) {
        return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    function finishOrder() {

        if (tipoPagamento === 'Local') {
            if (cartType === 'Pronta Entrega') {
                sendOrder();
            }
            else {
                sendAgendamento();
            }
        }
        else { //Pagamento Online
            if (metodoPagamento === 'Cartão') {
                getEncryptCard();
            }
            else {
                handlePixPayment();
            }
        }

        // tipoPagamento === 'Local' ? (cartType === 'Pronta Entrega' ? sendOrder : sendAgendamento) : getEncryptCard
    }

    return (
        <div className="stepper-cart">
            <Stepper sx={{ padding: '10px 0' }} alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <div className="stepper-cart-content">
                    <div className="stepper-cart-content-group">
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FinishedOrderComponent handleReset={handleReset} />
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Voltar ao inicio</Button>
                        </Box>
                    </div>
                </div>
            )
                :
                activeStep === 0 ? (
                    <div className="stepper-cart-content">
                        <div className="stepper-cart-content-group">
                            <div className="stepper-cart-content-title">
                                <Chip label={cartType} sx={{ backgroundColor: '#743821', color: '#ffffff' }} />
                            </div>
                            <div className="cart-modal-products">
                                <div className="cart-modal-products-items">
                                    {
                                        cartProducts.map((product, index) => {
                                            return (
                                                <CartProductComponent key={`${index}-${product.id}`} product={product} cartIndex={index} calcTotalCartValue={calcTotalCartValue} />
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="cart-total-value-group">
                                <div className="cart-observacao">
                                    <label htmlFor=""><i className="fa-solid fa-comment-dots"></i> Alguma observação?</label>
                                    <textarea value={orderObservation} onChange={e => setOrderObservation(e.target.value)} placeholder="..." cols={2}></textarea>
                                </div>

                                <div className="cart-total">
                                    <div className="cart-total-value">
                                        <div className="cart-total-value-title">
                                            <i className="fa-solid fa-sack-dollar"></i>
                                            <div>Valor Total</div>
                                            <Tooltip enterTouchDelay={0} TransitionComponent={Zoom} title="Pode haver alteração no valor conforme taxa de entrega" placement="top">
                                                <span>
                                                    <i className="fa-solid fa-circle-info"></i>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className="cart-total-value-price">R$ {formatMoney(calcTotalCartValue())}</div>
                                    </div>
                                </div>
                            </div>

                            {
                                !aberto &&
                                <Alert severity="warning" >Essa loja está fechada no momento. Assim que abrir você poderá finalizar seu pedido!</Alert>
                            }

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Voltar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <Button disabled={!aberto} onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                </Button>
                            </Box>
                        </div>

                    </div>
                )
                    :
                    activeStep === 1 ? (
                        <div className="stepper-cart-content">
                            <div className="stepper-cart-content-group-info">
                                <div className="stepper-cart-content-group-identity-input">
                                    {
                                        <>
                                            <div className="stepper-cart-input-group" >

                                                <TextField
                                                    required
                                                    error={phoneError}
                                                    disabled={isLoadingClient}
                                                    fullWidth
                                                    label="Telefone/Celular"
                                                    value={phone}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        inputComponent: MaskedInput,
                                                        inputProps: { mask: maskTel(phone) },
                                                        endAdornment: isLoadingClient && <InputLoading />
                                                    }}
                                                    onBlur={(e) => loadClientByPhone(e.target.value)}
                                                    onChange={(e) => loadClientByPhone(e.target.value)} />
                                            </div>
                                            <div className="stepper-cart-input-group" >
                                                <div>
                                                    <small>Deseja CPF na nota?</small>
                                                    <Switch checked={cpfNaNota} onChange={e => setCpfNaNota(e.target.checked)} />
                                                </div>
                                                <TextField
                                                    disabled={isLoadingClient}
                                                    fullWidth
                                                    label='CPF'
                                                    value={cpf}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        inputComponent: MaskedInput,
                                                        inputProps: { mask: "000.000.000-00" },
                                                        endAdornment: isLoadingClient && <InputLoading />
                                                    }}
                                                    onBlur={(e) => loadClientByCpf(e.target.value)}
                                                    onChange={(e) => loadClientByCpf(e.target.value)} />
                                            </div>

                                        </>
                                        // <>
                                        //     <div className="stepper-cart-input-group" >
                                        //         <label className="stepper-cart-label"><i className="fa-solid fa-phone"></i> Telefone</label>
                                        //         <IMaskInput placeholder="Informe um telefone/celular" label="Telefone" mask="(00)0000[0]-0000" value={phone} onChange={() => { }} onAccept={value => loadClientByPhone(`${value}`)} />
                                        //     </div>
                                        //     <div className="stepper-cart-input-group" >
                                        //         <label className="stepper-cart-label"><i className="fa-solid fa-id-card"></i> CPF</label>
                                        //         <IMaskInput placeholder="Informe um CPF" mask="000.000.000-00" value={cpf} onChange={() => { }} onAccept={value => loadClientByCpf(`${value}`)} />
                                        //     </div>

                                        // </>

                                    }
                                </div>
                                <div>
                                    <TextField
                                        required
                                        error={nameError}
                                        disabled={isLoadingClient}
                                        label="Nome"
                                        value={name}
                                        InputProps={{
                                            endAdornment: isLoadingClient && <InputLoading />
                                        }}
                                        onChange={e => setName(e.target.value)}
                                        fullWidth
                                    />
                                </div>

                                <div>
                                    <TextField select label="Retirada/Delivery" value={deliveryType} onChange={e => (setDeliveryType(e.target.value), calcFreight(e.target.value))} fullWidth>
                                        <MenuItem value="R">Retirar no local</MenuItem>
                                        <MenuItem value="D">Delivery</MenuItem>
                                        {
                                            company?.HorarioMinimoRetirada && company.HorarioMaximoRetirada && cartType === "Pronta Entrega" &&
                                            <MenuItem value="M">Retirar no local com hora marcada</MenuItem>
                                        }
                                    </TextField>
                                </div>
                                {
                                    deliveryType === 'M' && company?.HorarioMinimoRetirada && company?.HorarioMaximoRetirada && cartType === "Pronta Entrega" &&
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>

                                            <div> <i className="fa-regular fa-clock"></i> Horário de retirada (entre {format(getDateFromHours(company?.HorarioMinimoRetirada), 'HH:mm')} e {format(getDateFromHours(company?.HorarioMaximoRetirada), 'HH:mm')})</div>
                                            <DemoContainer
                                                components={[
                                                    'MobileTimePicker',
                                                ]}
                                            >
                                                <MobileTimePicker label="Selecione um horário" disablePast minTime={getDateFromHours(company?.HorarioMinimoRetirada)} maxTime={add(getDateFromHours(company?.HorarioMaximoRetirada), { seconds: 59 })} value={markedHour} onChange={(time) => setMarkedHour(time)} sx={{ width: '100%' }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                }

                                {
                                    deliveryType === 'R' && cartType === "Encomenda" ?

                                        <div className="form-field-row margin-top">
                                            <div className="form-field-row-item">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <label className="stepper-cart-label"><i className="fa-regular fa-clock"></i> Data de retirada </label>
                                                    <DemoContainer

                                                        components={[
                                                            'MobileTimePicker',
                                                        ]}
                                                    >
                                                        <DatePicker shouldDisableDate={date => {
                                                            const horarioAbertura = getHorarioAbertura(date);

                                                            return horarioAbertura === "00:00:00.0000000";
                                                        }}
                                                            onAccept={() => {
                                                                setTimeout(() => {
                                                                    setIsIncorretDate(false);
                                                                }, 50);
                                                            }}
                                                            onError={
                                                                () => setIsIncorretDate(true)
                                                            }
                                                            label="Selecione uma data" disablePast value={dataRetirada} onChange={date => setDataRetirada(date)} sx={{ width: '100%' }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>


                                            <div className="form-field-row-item">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <label className="stepper-cart-label"><i className="fa-regular fa-clock"></i> Horário da retirada</label>
                                                    <DemoContainer
                                                        components={[
                                                            'MobileTimePicker',
                                                        ]}
                                                    >
                                                        <MobileTimePicker
                                                            onAccept={() => {
                                                                setIsIncorretHour(false);
                                                            }}
                                                            onError={() => {
                                                                setIsIncorretHour(true);
                                                            }}
                                                            disabled={isIncorrectDate}
                                                            disablePast={!dataRetirada || (format(new Date(), 'yyyy-MM-dd') === format(dataRetirada, 'yyyy-MM-dd'))}
                                                            minTime={getDataEHoraParaPrimeiroPedidoAgendamento()}
                                                            maxTime={getDataEHoraFechamento()}
                                                            label="Selecione um horário"
                                                            value={horaRetirada}
                                                            onChange={(time, context) => {
                                                                setIsIncorretHour(!!context.validationError);
                                                                setHoraRetirada(time);
                                                            }}
                                                            sx={{ width: '100%' }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                            </div>
                                        </div>

                                        : deliveryType === 'D' && cartType === "Encomenda" &&

                                        <div className="form-field-row margin-top">
                                            <div className="form-field-row-item">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <label className="stepper-cart-label"><i className="fa-regular fa-clock"></i> Data de entrega</label>
                                                    <DemoContainer

                                                        components={[
                                                            'MobileTimePicker',
                                                        ]}
                                                    >

                                                        <DatePicker shouldDisableDate={date => {
                                                            const horarioAbertura = getHorarioAbertura(date);

                                                            return horarioAbertura === "00:00:00.0000000";
                                                        }}
                                                            onAccept={() => {
                                                                setTimeout(() => {
                                                                    setIsIncorretDate(false);
                                                                }, 50);
                                                            }}
                                                            onError={
                                                                () => setIsIncorretDate(true)
                                                            }
                                                            label="Selecione uma data" disablePast value={dataEntrega} onChange={date => setDataEntrega(date)} sx={{ width: '100%' }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>

                                            <div className="form-field-row-item">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <label className="stepper-cart-label"><i className="fa-regular fa-clock"></i> Horário da entrega</label>
                                                    <DemoContainer
                                                        components={[
                                                            'MobileTimePicker',
                                                        ]}
                                                    >
                                                        <MobileTimePicker
                                                            onAccept={() => {
                                                                setIsIncorretHour(false);
                                                            }}
                                                            onError={() => {
                                                                setIsIncorretHour(true);
                                                            }}
                                                            disabled={isIncorrectDate}
                                                            disablePast={!dataEntrega || (format(new Date(), 'yyyy-MM-dd') === format(dataEntrega, 'yyyy-MM-dd'))}
                                                            minTime={getDataEHoraParaPrimeiroPedidoAgendamento()}
                                                            maxTime={getDataEHoraFechamento()}
                                                            label="Selecione um horário"
                                                            value={horaEntrega}
                                                            onChange={(time, context) => {
                                                                setIsIncorretHour(!!context.validationError);
                                                                setHoraEntrega(time);
                                                            }}
                                                            sx={{ width: '100%' }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>

                                            </div>
                                        </div>
                                }

                                {
                                    deliveryType == 'D' &&

                                    <div className="form-field-group address-field">
                                        <div className="address-field-title">
                                            <label className="stepper-cart-label"><i className="fa-solid fa-road"></i> Endereço de entrega</label>
                                        </div>
                                        <div className="form-field-row">
                                            <IMaskInput className="masked-input" placeholder="CEP" mask="00000-000" value={cep} onChange={() => { }} onAccept={value => setCep(`${value}`)} onKeyUp={e => findAddressByCep(e)} />
                                            <TextField value={cidade} onChange={e => setCidade(e.target.value)} placeholder="Cidade" disabled fullWidth />
                                            <TextField value={uf} onChange={e => setUf(e.target.value)} placeholder="UF" disabled onBlur={() => calcFreight(deliveryType)} fullWidth />
                                        </div>
                                        <div className="form-field-row">
                                            <TextField value={logradouro} onChange={e => setLogradouro(e.target.value)} placeholder="Logradouro" onBlur={() => calcFreight(deliveryType)} inputProps={{ maxLength: 60 }} fullWidth />
                                        </div>
                                        <div className="form-field-row">
                                            <TextField value={numero} onChange={e => setNumero(e.target.value)} placeholder="Número" onBlur={() => calcFreight(deliveryType)} fullWidth />
                                            {
                                                company?.tipo_taxa_entrega === 'C' ?
                                                    <FormControl fullWidth>

                                                        <InputLabel id="label-bairro">Bairro</InputLabel>
                                                        <Select
                                                            labelId="label-bairro"
                                                            value={bairro}
                                                            label="Bairro"
                                                            placeholder="Selecione um bairro"
                                                            onChange={e => {
                                                                if (e.target.value != '') {
                                                                    setBairro(e.target.value)
                                                                } else {
                                                                    setFreight(null);
                                                                }
                                                            }}

                                                        >
                                                            {/* <MenuItem value=''>Selecione um bairro</MenuItem> */}
                                                            {
                                                                bairros.map(bairro => <MenuItem key={bairro.Id} value={`${bairro.Bairro}`}>{bairro.Bairro}</MenuItem>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    <TextField value={bairro} onChange={e => setBairro(e.target.value)} placeholder="Bairro" onBlur={() => calcFreight(deliveryType)} inputProps={{ maxLength: 60 }} fullWidth />
                                            }

                                        </div>
                                        <div className="form-field-row">
                                            <TextField value={complemento} onChange={e => setComplemento(e.target.value)} placeholder="Complemento" inputProps={{ maxLength: 20 }} fullWidth />
                                        </div>
                                    </div>
                                }

                                {
                                    isLoadingClient ?
                                        <CircularProgress sx={{ margin: 'auto' }} />
                                        :
                                        <>
                                            {
                                                !(freight === null && deliveryType === "D") ?
                                                    <>
                                                        <div className="stepper-cart-content-group-user-info">
                                                            <div>
                                                                <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Taxa de entrega</div>
                                                                <div className="stepper-cart-content-group-user-info-value">R$ {freight && formatMoney(freight)}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <Alert severity="warning">
                                                        <AlertTitle> <b>Aviso</b></AlertTitle>
                                                        Serviço de delivery indisponível nesse endereço. Selecione a opção para retirar no local.
                                                    </Alert>
                                            }
                                        </>
                                }

                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Voltar
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />

                                <LoadingButton onClick={() => {
                                    calcFreight(deliveryType);
                                    handleNextIdentification();
                                }
                                }
                                    loading={isCreatingClient}
                                    disabled={
                                        (freight === null && deliveryType === "D") ||
                                        (deliveryType === "D" &&
                                            (cidade === '' || uf === '' || logradouro === '' || bairro === '' || numero === '')
                                        )} >
                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                </LoadingButton>
                            </Box>
                        </div>
                    )
                        :
                        activeStep === 2 ? (

                            <div className="stepper-cart-content">

                                <div className="stepper-cart-content-group">
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-user"></i> Nome</div>
                                                <div className="stepper-cart-content-group-user-info-value">{client?.Nome}</div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-tag"></i> Tipo</div>
                                                <div className="stepper-cart-content-group-user-info-value">{deliveryType === "D" ? "Entrega" : "Retirada no local"}</div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                {
                                                    deliveryType === 'D' ?
                                                        <>
                                                            <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Endereço de entrega</div>
                                                            <div className="stepper-cart-content-group-user-info-value">{logradouro}, {numero}, {bairro}, {cidade}</div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="stepper-cart-content-group-user-info-title"><i className="fa-solid fa-location-dot"></i> Endereço para retirada</div>
                                                            <div className="stepper-cart-content-group-user-info-value">{company?.Endereco}, {company?.Cidade}, {company?.CEP && cepMask(company?.CEP)}</div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"> <i className="fa-solid fa-cart-shopping"></i> Produtos</div>
                                                <div className="stepper-cart-content-group-user-info-order">
                                                    {
                                                        cartProducts.map((cartProduct, index) => {
                                                            return (
                                                                <div key={index} className="stepper-cart-content-group-user-info-order-product">
                                                                    <div>{cartProduct.fatorPeso > 0 ? `${(cartProduct.quantity * cartProduct.fatorPeso).toFixed(3)}kg` : `${cartProduct.quantity}x`} - {(cartProduct.description).toLocaleLowerCase()}</div>
                                                                    <div className="stepper-cart-content-group-user-info-order-product-price">R$ {cartProduct.fatorPeso > 0 ? formatMoney(cartProduct.price * cartProduct.fatorPeso) : formatMoney(cartProduct.price)}</div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="stepper-cart-content-group-user-info-order-subtotal">
                                                        <div style={{ fontWeight: 'initial' }}> <i className="fa-solid fa-coins"></i> Subtotal</div>
                                                        <div className="stepper-cart-content-group-user-info-order-subtotal-price">R$ {formatMoney(calcTotalCartValue())}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="stepper-cart-content-group-user-info">
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-motorcycle"></i> Taxa de entrega</div>
                                                <div className="stepper-cart-content-group-user-info-value">R$ {freight ? formatMoney(freight) : 0}</div>
                                            </div>
                                            <div>
                                                <div className="stepper-cart-content-group-user-info-title"><i className="fas fa-coins"></i> Valor total</div>
                                                <div className="stepper-cart-content-group-user-info-value">R$ {freight ? (formatMoney(calcTotalCartValue() + freight)) : formatMoney(calcTotalCartValue())}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Voltar
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    <LoadingButton loading={isGettingPublicKey} onClick={handleNext} disabled={!client || (tipoPagamento === 'Online' && showErrorMessage)} >
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                    </LoadingButton>
                                </Box>
                            </div>
                        )
                            :
                            activeStep === 3 ? (
                                <div className="stepper-cart-content">
                                    <div className="stepper-cart-content-group">

                                        {
                                            pixPaymentResponse ?
                                                <QRCodePaymentStep pixPaymentResponse={pixPaymentResponse} cartType={cartType ?? "Pronta Entrega"} onConfirmPayment={() => setActiveStep(steps.length)} onClear={() => setPixPaymentResponse(null)} />
                                                :
                                                <div className="stepper-cart-content-group-info">
                                                    <div className="stepper-cart-tipo-pagamento">
                                                        <label htmlFor="">Tipo de pagamento</label>
                                                        {
                                                            <>
                                                                <div className="stepper-cart-switch-tipo-pagamento">
                                                                    <div className='stepper-cart-switch-activator' style={{ left: tipoPagamento === 'Online' ? '0' : '50%' }}></div>
                                                                    <div className="stepper-cart-switch-tipo-pagamento-item">
                                                                        <input type="radio" checked={tipoPagamento === "Online"} onChange={e => e.target.checked && setTipoPagamento("Online")} id="pagamento-online" name="tipo-pagamento" />
                                                                        <label htmlFor="pagamento-online">
                                                                            <div className='product-modal-switch-item-icon'>
                                                                                <i className="fa-solid fa-globe"></i>
                                                                            </div>
                                                                            <span>Pagamento online</span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="stepper-cart-switch-tipo-pagamento-item">
                                                                        <input type="radio" checked={tipoPagamento === "Local"} onChange={e => e.target.checked && setTipoPagamento("Local")} id="pagamento-local" name="tipo-pagamento" />
                                                                        <label htmlFor="pagamento-local">
                                                                            <div className='product-modal-switch-item-icon'>
                                                                                <i className="fa-solid fa-store"></i>
                                                                            </div>
                                                                            <span>Pagar no local</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    {
                                                        tipoPagamento === "Local" ?
                                                            <>
                                                                <div>
                                                                    <TextField select label="Forma de pagamento" value={paymentMethod} onChange={e => setPaymentMethod(e.target.value)} fullWidth>
                                                                        <MenuItem value="D">Dinheiro</MenuItem>
                                                                        <MenuItem value="C">Cartão de crédito/débito</MenuItem>
                                                                    </TextField>
                                                                </div>
                                                                {
                                                                    paymentMethod === "D" &&
                                                                    <div>
                                                                        <small>Precisa de troco?</small>
                                                                        <div className="change-money-question-group">
                                                                            <div className="change-money-question-group-option">
                                                                                <input id="troco" type="radio" name="needChangeMoney" checked={needChangeMoney} onChange={() => setNeedChangeMoney(true)} />
                                                                                <label htmlFor="troco">Sim, preciso</label>
                                                                            </div>
                                                                            <div className="change-money-question-group-option">
                                                                                <input id="sem-troco" type="radio" name="needChangeMoney" checked={!needChangeMoney} onChange={() => setNeedChangeMoney(false)} />
                                                                                <label htmlFor="sem-troco">Não preciso</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    paymentMethod === "D" && needChangeMoney &&
                                                                    <div className="stepper-cart-input-group">
                                                                        <div className="label-troco">
                                                                            Troco para:
                                                                        </div>
                                                                        <IntlCurrencyInput currency="BRL" value={changeMoney} config={currencyConfig} max={99999} onChange={(event, value, maskedValue) => setChangeMoney(Number(value))} />
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div className="online-payment-methods">
                                                                    <div className="stepper-cart-forma-pagamento">
                                                                        {
                                                                            <>
                                                                                <div className="stepper-cart-forma-pagamento-option">
                                                                                    <input checked={metodoPagamento === 'Cartão'} type="checkbox" />
                                                                                    <label onClick={() => setMetodoPagamento("Cartão")} htmlFor="">
                                                                                        <i className="fa-solid fa-credit-card"></i>
                                                                                        <span>Cartão</span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="stepper-cart-forma-pagamento-option">
                                                                                    <input checked={metodoPagamento === 'Pix'} type="checkbox" />
                                                                                    <label onClick={() => setMetodoPagamento("Pix")} htmlFor="">
                                                                                        <i className="fa-brands fa-pix"></i>
                                                                                        <span>PIX</span>
                                                                                    </label>

                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    metodoPagamento === "Cartão" ?
                                                                        <div>
                                                                            {

                                                                                <div className="pagamento">
                                                                                    <div style={{ display: 'flex', width: '100%', gap: "5%", justifyContent: 'center', color: 'black' }}>
                                                                                        <div style={{ marginBottom: "5%" }}>
                                                                                            <h2 style={{ marginBottom: "2%" }}>Dados do pagamento</h2>
                                                                                            <h5>(Cartão de crédito)</h5>
                                                                                        </div>
                                                                                        <i className="fa-regular fa-credit-card" style={{ fontSize: '40px' }}></i>
                                                                                    </div>
                                                                                    <div>
                                                                                        <form id="formPagamento">
                                                                                            <div style={{ display: 'flex', marginBottom: '2%' }}>
                                                                                                <div className="numeroCartao">
                                                                                                    <div className="label-form">
                                                                                                        Número do cartão
                                                                                                    </div>
                                                                                                    <input type="tel" name="txNumerodoCartao" className="field" next-field="txValidadeCartao" id="txNumerodoCartao" placeholder="0000 0000 0000 0000" maxLength={19}
                                                                                                        value={cardMask(cardNumber)} onChange={e => { setCardNumber(cardMask(e.target.value)); setShowErrorMessage(false); }} />
                                                                                                </div>
                                                                                                <div className="validadeCartao">
                                                                                                    <div className="label-form">
                                                                                                        Validade
                                                                                                    </div>
                                                                                                    <input type="text" name="txValidadeCartao" className="field" next-field="txCodSeguranca" id="txValidadeCartao" placeholder="MM/AAAA" maxLength={7}
                                                                                                        value={monthYearMask(cardDate)} onChange={e => { setCardDate(e.target.value); setShowErrorMessage(false); }} />
                                                                                                </div>
                                                                                                <div className="codigoSeguranca">
                                                                                                    <div >
                                                                                                        <div className="label-form">
                                                                                                            CVV
                                                                                                        </div>
                                                                                                        <input type="tel" name="txCodSeguranca" id="txCodSeguranca" className="field" next-field="txCpfdoCartao" placeholder="123" maxLength={4}
                                                                                                            value={securityCodeMask(securityCode)} onChange={e => { setSecurityCode(e.target.value); setShowErrorMessage(false); }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ display: 'flex', marginBottom: '2%' }}>
                                                                                                <div className="cpf">
                                                                                                    <div className="label-form">
                                                                                                        CPF
                                                                                                    </div>
                                                                                                    <input type="text" name="txCpfdoCartao" id="txCpfdoCartao" className="field" next-field="txTitulardoCartao" placeholder="000.000.000-00" maxLength={14}
                                                                                                        value={cpfMask(cpfCartao)} onChange={e => { setCpfCartao(e.target.value); setShowErrorMessage(false); }} />
                                                                                                </div>
                                                                                                <div className="titularCartao">
                                                                                                    <div className="label-form">
                                                                                                        Titular
                                                                                                    </div>
                                                                                                    <input type="text" name="txTitulardoCartao" className="field" next-field="txEmailTitular" id="txTitulardoCartao" placeholder="Nome do titular"
                                                                                                        value={cardCostumer} onChange={e => { setCardCustomer(e.target.value); setShowErrorMessage(false); }} />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ display: 'flex', marginBottom: '2%' }}>
                                                                                                <div className="emailTitular">
                                                                                                    <div className="label-form">
                                                                                                        Email
                                                                                                    </div>
                                                                                                    <input type="text" name="txEmailTitular" className="field" id="txEmailTitular" placeholder="Email"
                                                                                                        value={email} onChange={e => { setEmail(e.target.value); setShowErrorMessage(false); }} />
                                                                                                </div>
                                                                                            </div>
                                                                                            {showErrorMessage &&
                                                                                                <div>
                                                                                                    <h5 style={{ color: "red" }}>*Por favor, verifique se o dados estão preenchidos corretamente!*</h5>
                                                                                                </div>
                                                                                            }
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div className="payment-form-row">
                                                                                <div className="payment-form-item">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label='CPF'
                                                                                        value={cpfCartao}
                                                                                        autoComplete="off"
                                                                                        error={cpfError}
                                                                                        InputProps={{
                                                                                            inputComponent: MaskedInput,
                                                                                            inputProps: { mask: "000.000.000-00" },
                                                                                        }}
                                                                                        onBlur={(e) => setCpfCartao(e.target.value)}
                                                                                        onChange={(e) => setCpfCartao(e.target.value)} />
                                                                                </div>
                                                                                <div className="payment-form-item">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label='Nome completo'
                                                                                        value={cardCostumer}
                                                                                        error={titularError}
                                                                                        autoComplete="off"
                                                                                        onBlur={(e) => setCardCustomer(e.target.value)}
                                                                                        onChange={(e) => setCardCustomer(e.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="payment-form-row">
                                                                                <div className="payment-form-item">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label='Email'
                                                                                        value={email}
                                                                                        error={emailError}
                                                                                        autoComplete="off"
                                                                                        onBlur={(e) => setEmail(e.target.value)}
                                                                                        onChange={(e) => setEmail(e.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                        }

                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button
                                                disabled={!!pixPaymentResponse || isSendingOrder || isGettingPublicKey}
                                                color="inherit"
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                Voltar
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />

                                            {
                                                !pixPaymentResponse &&
                                                <LoadingButton loading={isSendingOrder || isGettingPublicKey} onClick={finishOrder}>
                                                    {activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
                                                </LoadingButton>
                                            }

                                        </Box>
                                    </div>

                                </div>
                            )
                                :
                                <div>Err</div>
            }

        </div >

    )
}