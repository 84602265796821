import { useState, useEffect } from 'react';
import { IMaskInput } from "react-imask";

import { ptBR } from '@mui/x-date-pickers/locales';
import { ptBR as ptBRfns } from 'date-fns/locale';

import './Orders.css';
import { api } from '../../lib/api';
import customToast from '../../toast/customToast';
import OrderCardComponent from '../../components/OrderCard/OrderCardComponent';
import AgendamentoCardComponent from '../../components/AgendamentoCard/AgendamentoCardComponent';
import { CircularProgress } from '@mui/material';
import { useCart } from '../../hook/useCart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { IOrder } from '../../interfaces/IOrder';
import { IAgendamento } from '../../interfaces/IAgendamento';
import NavBarComponent from '../../components/Widgets/NavBar/NavBarComponent';
import { TabContext, TabPanel } from '@mui/lab';

import { motion, useIsPresent } from 'framer-motion';



export default function Orders() {

    const [name, setName] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [tabValue, setTabValue] = useState<string>('1');

    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

    const [orders, setOrders] = useState<IOrder[]>([]);

    const [agendamentos, setAgendamentos] = useState<IAgendamento[]>([]);

    const isPresent = useIsPresent();


    const { userOrder, setUserOrder } = useCart();

    useEffect(() => {
        if (userOrder.length === 11) {
            changePhone(userOrder);
        }
    }, [])

    useEffect(() => {
        if (removeMask(userOrder).length === 11) {
            changeDate(startDate, endDate, removeMask(userOrder));
        }
    }, [tabValue]);

    async function changePhone(value: string) {

        setUserOrder(value);

        if (value.length == 11) {
            setIsLoadingData(true);
            try {
                const res = await api.get(`/client/phone/${value}/orders`);

                setName(res.data.Nome);
                setCity(res.data.Cidade);

                changeDate(startDate, endDate, value);

            } catch (err) {
                setName('');
                setCity('');
                customToast.error("Cliente não encontrado");
            } finally {
                setIsLoadingData(false);
            }
        }
        else {
            setName('');
            setCity('');
            setOrders([]);
        }
    }

    function removeMask(value: string) {
        return value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '');
    }

    async function changeDate(startDate: Date, endDate: Date, phone: string) {

        setStartDate(startDate);
        setEndDate(endDate);

        var formatedStartDate = format(startDate, 'yyyy-MM-dd');
        var formatedEndDate = format(endDate, 'yyyy-MM-dd');

        setIsLoadingData(true);

        try {

            if (tabValue === "2") {

                const result = await api.get(`/agendamento/client/${phone}/start-date/${formatedStartDate}/end-date/${formatedEndDate}`);

                var items: IAgendamento[] = result.data;

                setAgendamentos(items);
            } else {

                const result = await api.get(`/order/client/${phone}/start-date/${formatedStartDate}/end-date/${formatedEndDate}`);

                var itemsPE: IOrder[] = result.data;

                setOrders(itemsPE);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoadingData(false);
        }
    }

    return (
        <motion.div
            initial={{ x: "0%", opacity: 0 }}
            animate={{ x: "0", opacity: 1 }}
            exit={{ x: "-40%", opacity: 0 }}
            className="app-container"
        >
            <NavBarComponent />
            <div className="orders-page">
                <div className="orders-page-inputs">
                    <div className="input-group">
                        <label>Telefone/Celular</label>
                        <IMaskInput label="Telefone/celular" mask="(00)00000-0000" defaultValue={userOrder} onChange={() => { }} onAccept={value => changePhone(removeMask(`${value}`))} />
                    </div>
                    <div className='input-group'>
                        <label>Nome</label>
                        <input value={name} disabled />
                    </div>
                    <div className='input-group'>
                        <label>Cidade</label>
                        <input value={city} disabled />
                    </div>
                </div>
                <div className='orders-page-items'>
                    <div className='orders-page-items-filter'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <div className='orders-page-items-filter-group'>
                                <label> <i className="fa-solid fa-calendar-day"></i> Data inicial</label>
                                <DatePicker
                                    format='dd/MM/yyyy'
                                    closeOnSelect
                                    disableFuture
                                    value={startDate}
                                    maxDate={endDate}
                                    onChange={(value) => changeDate(value as Date, endDate, userOrder)
                                    } />
                            </div>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRfns} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <div className='orders-page-items-filter-group'>
                                <label> <i className="fa-solid fa-calendar-days"></i> Data final</label>
                                <DatePicker
                                    format='dd/MM/yyyy'
                                    closeOnSelect
                                    disableFuture
                                    value={endDate}
                                    minDate={startDate}
                                    onChange={(value) => (setEndDate(value as Date), changeDate(startDate, value as Date, userOrder))
                                    } />
                            </div>
                        </LocalizationProvider>
                    </div>

                    <div className='orders-switch'>
                        <div className='product-modal-switch'>
                            <div className='product-modal-switch-activator' style={{ left: tabValue === '1' ? '0' : '50%' }}></div>

                            <div className='product-modal-switch-item'>
                                <input id='switch-fatia' checked={tabValue === '1'} onChange={() => setTabValue('1')} type="radio" name='switch-product' />
                                <label htmlFor="switch-fatia">
                                    <div className='product-modal-switch-item-icon'>
                                        <i className="fa-solid fa-utensils"></i>
                                    </div>
                                    <span>Pronta-entrega</span>
                                </label>
                            </div>
                            <div className='product-modal-switch-item'>
                                <input id='switch-vitrine' checked={tabValue === '2'} onChange={() => setTabValue('2')} type="radio" name='switch-product' />
                                <label htmlFor="switch-vitrine">
                                    <div className='product-modal-switch-item-icon'>
                                        <i className="fa-solid fa-cake-candles"></i>
                                    </div>
                                    <span>Encomendas</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <TabContext value={tabValue}>
                        <TabPanel value='1' sx={{ padding: 0 }}>
                            <div className='orders-tab-header'>
                                <span className='orders-tab-title'>Pronta entrega</span>
                                <div className='orders-page-items-title'>
                                    {orders.length} pedido(s) encontrados
                                </div>
                            </div>
                            {
                                isLoadingData ?
                                    <CircularProgress sx={{ display: 'flex', margin: '0 auto' }} />
                                    :
                                    <div className='orders-page-items-content'>
                                        {
                                            orders.map(order => {
                                                return (
                                                    <OrderCardComponent key={order.Id} order={order} />
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </TabPanel>

                        <TabPanel value='2' sx={{ padding: 0 }}>
                            <div className='orders-tab-header'>
                                <span className='orders-tab-title'>Agendamentos</span>
                                <div className='orders-page-items-title'>
                                    {agendamentos.length} pedido(s) encontrados
                                </div>
                            </div>

                            {
                                isLoadingData ?
                                    <CircularProgress sx={{ display: 'flex', margin: '0 auto' }} />
                                    :
                                    <div className='orders-page-items-content'>
                                        {
                                            agendamentos.map(agendamento => {
                                                return (
                                                    <AgendamentoCardComponent key={agendamento.Id} agendamento={agendamento} />
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </TabPanel>
                    </TabContext>
                </div>
            </div>
        </motion.div>
    )
}