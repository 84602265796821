import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Orders from "../pages/Orders";
import Encomenda from "../pages/Encomenda";
import CompanyLoad from "../middlewares/CompanyLoad";
import NotFound from "../pages/NotFound/NotFound";
import { AnimatePresence } from "framer-motion";

export default function RoutesApp() {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait" initial={false}>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<CompanyLoad> <Home /> </CompanyLoad>} />
                <Route path="/orders" element={<CompanyLoad> <Orders /> </CompanyLoad>} />
                <Route path="/encomenda" element={<CompanyLoad> <Encomenda /> </CompanyLoad>} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    )
}